.orderdetail {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  position: relative;
}
.orderdetail-container {
  width: 100%;
  height: 180px;
  margin-left: 32px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
  padding: 20px 28px 12px;
}

.orderdetail-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  line-height: 28px; /* 140% */
}

.orderdetail-separator {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 100%;
}

.orderdetail-information {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
}

.orderdetail-information-first {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
}

.orderdetail-information-first-row {
  font-weight: var(--font-weight-bold);
}

.orderdetail-information-first-row-text {
  color: var(--dark-500, #222729);
  padding-left: 4px;

  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.orderdetail-image-container {
  align-self: stretch;
  object-fit: cover;
  position: relative;
  width: 140px;
  float: right;
}
.orderdetail-image {
  width: 140px;
  object-fit: cover;
  height: 100px;
}
.orderdetail-container-description {
  display: flex;
  float: right;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.orderdetail-assignedpeople {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 4px;
  align-self: stretch;
}

.office-coworking-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.office-coworking-activitytail-container {
  width: 100%;
}

.office-coworking-activitytail-container-results {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 28px;
}

.office-coworking-activitytail-container-row {
  white-space: nowrap;
  display: flex;
  gap: 28px;
  width: 100%;
  padding-bottom: 24px;
}

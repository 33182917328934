.modal-wrapper-transaction-detail .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 650px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
  height: 630px;
}

.summary-text {
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: medium;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 15px;
}

.table-sending-modal
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}

.table-sending-modal .ant-table-wrapper .ant-table-thead {
  overflow: hidden;
  color: var(--Dark-100, #030330);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.table-sending-modal .ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f0f0f0;
}

.table-sending-modal
  .ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > :nth-child(2) {
  border-start-end-radius: 8px;
  justify-content: end;
  display: flex;
}

.table-sending-modal {
  top: 30px;
  position: relative;
}

.summary-credits {
  top: 70px;
  position: relative;
}

.rectangle-10 {
  background-color: var(--dark-50);
  height: 1px;
  position: absolute;
  width: 100%;
}

.container-credit-available {
  width: 100%;
  display: flex;
}

.credit-available-modal {
  display: flex;
  width: 50%;
  margin-bottom: 10px;
}

.credit-available-modal-text {
  display: flex;
  position: relative;
  justify-content: end;
  width: 50%;
}

.container-sub-credit-available {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.sub-credit-available-modal {
  display: flex;
  width: 50%;
  margin-bottom: 10px;
}

.sub-credit-available-modal-text {
  display: flex;
  position: relative;
  justify-content: end;
  width: 50%;
}

.container-remaining-credits {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.remaining-credits-modal {
  display: flex;
  padding-bottom: 10px;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: medium;
  font-weight: 600;
  width: 50%;
}

.remaining-credits-modal-text {
  display: flex;
  position: relative;
  width: 50%;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: medium;
  justify-content: end;
  font-weight: 600;
}

.admin-MR-modal {
  color: black;
  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);
  line-height: normal;
  position: relative;
  width: fit-content;
}

.table-transaction-details-user {
  display: flex;
}

.table-transaction-details-user-name {
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: medium;
  font-weight: 700;
  padding-left: 5px;
}

.table-transaction-details-user-email {
  font-family: Red Hat Display;
  font-size: 13px;
}
.table-transaction-details-credit {
  justify-content: end;
  display: flex;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: medium;
  font-weight: 700;
  padding-left: 5px;
}

.table-sending-modal .ant-table-body {
  overflow-y: scroll;
  max-height: 147px !important;
}

.admin-MR-wrapper-modal {
  align-items: center;
  background-color: #e9e9ea;
  border-radius: 20px;
  display: flex;
  gap: 4px;
  height: 26px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 26px;
}

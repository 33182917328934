.wellness-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.wellness-titlebar {
  margin-top: 20px;
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.wellness-titlebar-iconplace {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.wellness-titlebar-iconplace-icon {
  width: 20px;
  height: 20px;
}

.wellness-titlebar-text {
  color: var(--dark-500, #222729);

  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.wellness-switchbar-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: fit-content;
  height: 30px;
  font-size: 14px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
}

.wellness-container {
  height: 100%;
}

.wellness-welcome-widget {
  height: 280px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  background-size: cover;
  border: 1px solid;
  border-color: #d3d4d4;
  border-radius: 20px;
  background-image: url(../../img/wellness-welcome-widget.png);
  display: flex;
  align-items: center;
  align-content: center;
}

.wellness-welcome-widget-text {
  position: relative;
  margin-left: 57px;

  color: var(--dark-400, #4e5254);

  /* Bold/24 */
  width: 450px;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.wellness-servicearea {
  margin-top: 28px;
}

.wellness-servicearea-text-selection {
  color: var(--dark-500, #222729);

  /* SemiBold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.wellness-servicearea-row {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 28px;
  margin-top: 20px;
}

.wellness-ordersuccess-container {
  display: flex;
  padding: 12px 12px 12px 20px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: var(--corp-50, #ebf7f5);
}

.wellness-ordersuccess-textcontainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.wellness-ordersuccess-text {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.wellness-ordersuccess-icon {
  cursor: pointer;
}

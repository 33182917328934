.description-delete-modal {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.mn-delete-service-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 993;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-service-modal-container {
  background-color: #ffffff;
  height: auto;
  width: 400px;
  border-radius: 12px;
  padding: 28px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-service-modal-icon-info-container {
  background-color: #e9e9ea;
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
}

.delete-service-modal-buttons-container {
  display: flex;
  height: auto;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.delete-service-button-delete {
  background-color: #f26448;
  height: 40px;
  width: 100%;
}

.delete-service-button-dimiss {
  background-color: #ffffff;
  height: 40px;
  width: 100%;
}

.delete-service-button-dimiss-text {
  color: #222628;
  font-size: 14px;
  font-family: Red Hat Display;
  font-weight: 700;
}

.delete-service-button-cancel-text {
  color: #ffffff;
  font-size: 14px;
  font-family: Red Hat Display;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title-modal-first-access {
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 1000;
}

.description-modal-first-access {
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  top: 10px;
}

.description2-modal-first-access {
  color: #222729;
  position: relative;
  top: 25px;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.modal-wrapper-first-access .mn-modal .mn-modal__dialog {
  width: 510px;
  height: 460px;
}

.modal-wrapper-first-access
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header {
  position: relative;
  display: flex;
  padding: 15px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: white;
}

.modal-wrapper-first-access .mn-modal {
  background-color: transparent;
}

.modal-wrapper-first-access .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  height: 290px;
}

.first-access-first-row {
  display: flex;
  width: 100%;
}

.fisrt-access-modal-password {
  position: relative;
  top: 55px;
}

.modal-changepassword-first-access {
  color: var(--dark-250, #909394);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  width: 50%;
  display: flex;
  justify-content: end;
}

.label-textfield-first-access {
  display: flex;
  position: relative;
  margin-bottom: 4px;
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 50%;
}

.modal-wrapper-first-access .mn-modal .mn-modal__dialog .button.button-primary {
  border-radius: 6px;
  background: var(--corp-500, #36b39e);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
}

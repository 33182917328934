.button {
  display: inline-flex;
  font-family: "Red Hat Display";
  gap: 0.5rem;
  padding: 4px 8px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: var(--font-weight-semi-bold);
  transition: background-color 0.3s ease;
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
}
.button.button-small {
  height: 28px;
}
.button.button-medium {
  height: 32px;
}
.button.button-large {
  height: 40px;
}
.button.button-extra-large {
  height: 48px;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.s {
  height: 32px;
}

.button.m {
  height: 40px;
}

.button.l {
  height: 48px;
}

.button.xl {
  height: 56px;
}

.button.button-primary {
  background-color: var(--corp-500);
  color: #fff;
}

.button.button-primary:hover {
  background-color: var(--corp-475);
  color: #fff;
}
.button.button-primary:active {
  background-color: var(--corp-575);
  color: #fff;
}

.button.button-primary .button-icon svg path {
  stroke: white;
}

.button.button-secondary {
  background-color: var(--corp-150);
  color: #212529;
}
.button.button-secondary:hover {
  background-color: var(--corp-100);
  color: #212529;
}
.button.button-secondary:active {
  background-color: var(--corp-250);
  color: #212529;
}

.button.button-secondary .button-icon svg path {
  stroke: #36b39e;
}

.button.button-skip-secondary {
  background-color: #f26549;
}

.button.button-tertiary {
  background-color: #c3e8e2;
  color: #212529;
}
.button.button-tertiary:hover {
  background-color: var(--dark-50);
  color: #212529;
}
.button.button-tertiary:active {
  background-color: var(--dark-100);
  color: #212529;
}

.button.button-line {
  background-color: var(--white);
  border: 1px solid var(--dark-150);
  color: #212529;
}
.button.button-line:hover {
  background-color: var(--white);
  border: 1px solid var(--dark-250);
  color: #212529;
}
.button.button-line:active {
  background-color: var(--dark-25);
  border: 1px solid var(--dark-300);
  color: #212529;
}

.button.button-ghost {
  background-color: var(--white);
  color: #212529;
}

.button.button-ghost:hover {
  background-color: var(--dark-25);
  color: #212529;
}

.button.button-ghost:active {
  background-color: var(--dark-50);
  color: #212529;
}

.button.button-tertiary .button-icon svg path,
.button.button-line .button-icon svg path,
.button.button-ghost .button-icon svg path {
  stroke: #222729;
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.base-tab-element-selected {
  background-color: var(--global-colors-ui-white);
  box-shadow: 0px 1px 0px #2226281a;
  border-radius: 4px;
  height: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
}

.base-tab-element {
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 28px;
  justify-content: center;
  padding: 4px 12px;
  position: relative;
  align-items: center;
}

.base-tab-element-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.base-info {
  background: rgba(255, 204, 102, 0.15);
  width: 284px;
  padding: 12px 20px;
  margin-top: 20px;
}

.privacy-policy {
  color: var(--corp-500, #36b39e);
  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.modal-body {
  height: 352px;
}

.modal-wrapper-edit-personal-data .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 650px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
  height: 530px;
}

.input-first-and-last-name {
  display: flex;
  width: 100%;
}

.input-first-name {
  width: 100%;
}

.input-last-name {
  width: 100%;
}

.input-title-first-name {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
}

.input-title-last-name {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  left: 20px;
  margin-bottom: 6px;
}

.input-text-first-name {
  width: 275px;
}

.input-text-last-name {
  width: 275px;
  float: right;
}

.input-text-first-name .ant-input {
  height: 35px;
}

.input-text-last-name .ant-input {
  height: 35px;
}

.input-email-and-telephone {
  display: flex;
  width: 100%;
  margin-top: 15px;
}

.input-email {
  width: 100%;
}

.input-telephone {
  width: 100%;
}

.input-title-email {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
}

.input-title-telephone {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  left: 20px;
  margin-bottom: 6px;
}

.personal-data-virtual-cooworking {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  margin-bottom: 10px;
  margin-top: 20px;
}

.input-text-email {
  width: 275px;
}

.input-text-telephone {
  width: 275px;
  float: right;
}

.input-text-email .ant-input {
  height: 35px;
}

.input-text-telephone .ant-input {
  height: 35px;
}

.info-telephone {
  position: relative;
  left: 20px;
  font-size: 12px;
  height: 0px;
}

.required {
  display: flex;
}

.input-photo {
  position: relative;
  top: 35px;
}

.input-title-photo {
  display: flex;
}

.input-title-photo-text {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
}

.dragger-text {
  color: var(--Corp-500, #36b39e);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.input-dragger {
  border-radius: 8px;
  border: 1px dashed var(--Corp-250, #9bd9ce);
  background: var(--Corp-50, #ebf7f5);
}

.input-fiscal-code-and-role {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.input-fiscal-code {
  width: 100%;
}

.input-role {
  width: 100%;
}

.input-title-fiscal-code {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
}

.input-title-role {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  left: 20px;
  margin-bottom: 6px;
}

.input-text-fiscal-code {
  width: 275px;
}

.input-text-role {
  width: 275px;
  float: right;
}

.input-text-fiscal-code .ant-input {
  height: 35px;
}

.input-text-role .ant-input {
  height: 35px;
}

.input-nazionality-and-city {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.input-nazionality {
  width: 100%;
}

.input-city-modal {
  width: 100%;
}

.input-title-nazionality {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
}

.input-title-city-modal {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  left: 20px;
  margin-bottom: 6px;
}

.input-text-nazionality {
  width: 275px;
}

.input-text-city-modal {
  width: 275px;
  float: right;
}

.input-text-nazionality .ant-input {
  height: 35px;
}

.input-text-city-modal .ant-input {
  height: 35px;
}

.input-dragger .ant-upload-wrapper .ant-upload-drag {
  background: var(--Corp-50, #ebf7f5);
}

.input-dragger
  .ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error {
  border-color: var(--corp, #b6b9ba);
}

.input-dragger
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-name {
  overflow: hidden;
  color: var(--Dark-500, #222729);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.input-title-photo-advise {
  color: var(--Dark-250, #909394);
  text-align: right;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  position: relative;
  left: 316px;
  top: 10px;
}

.input-text-style {
  font-family: Red Hat Display;
  font-weight: 600;
}

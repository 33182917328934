.smartplan-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.smartplan-content .element-ic-laptop-instance {
  min-width: 20px !important;
  width: unset !important;
}

.smartplan-content .twenty-ic-laptop-instance {
  border-color: var(--corp-500) !important;
}

.smartplan-content .button-instance {
  left: 458px !important;
  position: absolute !important;
  top: 24px !important;
}

.smartplan-content .text-wrapper-18 {
  color: var(--red-500);

  font-size: var(--font-size-sm);
  font-style: var(--bold-12-font-style);
  font-weight: var(--font-weight-semi-bold);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.smartplan-content .icon-instance-node-3 {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.smartplan-content .element-ic-laptop-2 {
  left: 2px !important;
  top: 2px !important;
}

.smartplan-content .element-ic-laptop-3 {
  border-color: var(--global-colors-ui-white) !important;
}

.smartplan-activitytail-container {
  width: 100%;
}

.smartplan-activitytail-container-results {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 28px;
}

.smartplan-activitytail-container-row {
  white-space: nowrap;
  display: flex;
  gap: 14px;
  width: 100%;
  padding-bottom: 24px;
}

.smartplan-topbar-title-iconplace {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.smartplan-topbar {
  margin-top: 20px;
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.smartplan-topbar-title {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.smartplan-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.smartplan-activitytail-container-row-button {
  display: flex;
  width: 60px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--brd-section, rgba(34, 39, 41, 0.12));
  color: var(--corp-500, #36b39e);
  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.smartplan-activitytail-container-title {
  color: var(--dark-100, #343a3d);
  /* SemiBold/18 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  margin-bottom: 24px;
}

.smartplan-activitytail-results-bar {
  justify-content: space-between;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.smartplan-activitytail-results-title {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.smartplan-activitytail-results-title-one {
  color: #000;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.smartplan-activitytail-results-title-two {
  color: #000;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.smartplan-activitytail-results-order {
  display: flex;
  align-items: center;
  gap: 4px;
}

.smartplan-activitytail-results-order-text {
  color: #000;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.smartplan-activitytail-results-order-selection {
  cursor: pointer;
  display: flex;
}

.smartplan-activitytail-results-order-selection-text {
  color: #000;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.smartplan-activitytail-results-order-selection-vector {
  padding-top: 5px;
}

.smartplan-myorder-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.smartplan-ordersuccess-container {
  display: flex;
  padding: 12px 12px 12px 20px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: var(--corp-50, #ebf7f5);
}

.smartplan-ordersuccess-textcontainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.smartplan-ordersuccess-text {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.smartplan-ordersuccess-icon {
  cursor: pointer;
}

.smartplan-myorderempty-container {
  display: flex;
  padding: 60px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--dark-50, #e9e9ea);
}

.smartplan-myorderempty-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.smartplan-myorderempty-secondtext {
  width: 300px;
  color: var(--dark-350, #646869);
  text-align: center;

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.smartplan-myorderempty-firsttext {
  color: var(--dark-500, #222729);

  /* SemiBold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.smartplan-myorderempty-icon {
  opacity: 20%;
}

.sidebar-container {
  height: 100vh;
  padding: 0;
  flex-shrink: 0;
}

.sidebar-container .admin-wrapper {
  border: 0px none;
  height: 100%;
  position: relative;
  width: 280px;
}

.sidebar-container .icon-instance-node-2 {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.sidebar-container .admin-2 {
  background-color: transparent !important;
}

/* Scrollbar */

/* Chrome, Edge, and Safari */

/* width */
.sidebar-container ::-webkit-scrollbar {
  width: 12px !important;
}

/* Track */
.sidebar-container ::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
.sidebar-container ::-webkit-scrollbar-thumb {
  background: var(--white) !important;
  border-radius: 6px !important;
  border: 4px solid transparent !important;
  background-clip: padding-box !important;
}

/* Handle on hover */
.sidebar-container ::-webkit-scrollbar-thumb:hover {
  background: var(--white) !important;
  border: 2px solid transparent !important;
  border-radius: 6px !important;
  background-clip: padding-box !important;
}

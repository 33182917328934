.top-bar {
  display: flex;
  padding: 20px 32px;
}

.top-bar-icon-title-container {
  display: flex;
  align-items: center;
  float: left;
}

.top-bar-breadcrumb {
  font-size: 14px;
  color: #555555;
  margin-bottom: 8px;
  cursor: pointer;
}

.top-bar-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(50, 179, 154, 0.2);
  border-radius: 6px;
  margin-right: 8px;
  padding: 4px 5px;
}

.top-bar-icon-breadcrumb {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.top-bar-title {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  margin-right: 20px;
}

.top-bar-date {
  color: #343a3d;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  margin-left: auto;
  opacity: 0.5;
  align-items: center;
  float: right;
  display: flex;
}

.top-bar-left-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

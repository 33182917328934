.input-number-container {
  border-radius: 6px;
  border: 1px solid var(--dark-150, #bdbebf);
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(52, 58, 61, 0.1);
  display: flex;
  height: 40px;
  padding: 4px 8px 4px 10px;
  align-items: center;
  align-self: stretch;
}

.button-left,
.button-right {
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 4px;
  outline: none;
  flex: 1; /* Distribuisci lo spazio disponibile tra i pulsanti */
}

.input-number {
  background-color: white;
  /* border: 1px solid var(--dark-150); */
  border-radius: 6px;
  display: flex;
  width: 100px; /* Larghezza della casella centrale */
  justify-content: center;
  flex: 2; /* Distribuisci lo spazio disponibile per la casella centrale */
}

.number {
  font-size: 18px;
  font-weight: 700;
  color: #343a3d;
}

.footer {
  display: flex;
  padding: 30px 36px;
  align-items: center;
  justify-content: right;
}

.footer-copyright {
  color: #343a3d;
  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);
}

.footer-label {
  color: #343a3d;
  font-size: 12px;
  font-weight: var(--font-weight-light);
  margin-left: 14px;
}

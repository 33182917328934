.label {
  align-items: center;
  display: flex;
  position: relative;
  width: 300px;
}

.label .required-wrapper {
  align-items: flex-start;
  display: flex;
  padding: 0px 2px 0px 0px;
  position: relative;
  width: fit-content;
}

.label .required-instance {
  border: 1px solid !important;
  border-color: var(--corp-500) !important;
  min-width: 14px !important;
  position: relative !important;
  width: unset !important;
}

.label .design-component-instance-node {
  background-color: var(--red-500) !important;
  left: 3px !important;
  top: 3px !important;
}

.label .frame-3 {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 4px;
  position: relative;
}

.label .text-wrapper-2 {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 80px;
}

.label .element-ic-help {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.label .left-text-instance {
  flex: 1 !important;
  min-width: 142px !important;
  width: unset !important;
}

.label .left-text-2 {
  left: 77px !important;
}

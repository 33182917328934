.wellnessnutritionaldetail-container {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 36px;
}

.wellnessnutritionaldetail-container .wellnessnutritionaldetail-header {
  background-color: #ffffff;
  border: 1px none;
  height: 100%;
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}

.button-whatsApp {
  width: 100%;
  margin-bottom: 25px;
}

.botton-go-back {
  width: 100%;
  height: 100%;
}

.botton-go-back .button {
  width: 100%;
  height: 100%;
  background: transparent;
  box-shadow: 0 0 0 0;
}

.button-whatsApp .button.button-extra-large {
  width: 100%;
}

.wellnessnutritionaldetail-container .sidebar-admin-instance {
  height: 3092px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.wellnessnutritionaldetail-container .sidebar-admin-2 {
  height: 2932px !important;
}

.wellnessnutritionaldetail-container .icon-instance-node-2 {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.wellnessnutritionaldetail-container .sidebar-admin-3 {
  background-color: transparent !important;
}

.wellnessnutritionaldetail-container .sidebar-admin-4 {
  top: 3012px !important;
}

.wellnessnutritionaldetail-container .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  left: 989px;
  position: absolute;
  top: 3042px;
}

.wellnessnutritionaldetail-container .text-wrapper-6 {
  color: #343a3d;
  font-family: "Red Hat Display", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.wellnessnutritionaldetail-container .text-wrapper-7 {
  color: #343a3d;
  font-family: "Red Hat Display", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.wellnessnutritionaldetail-container
  .wellnessnutritionaldetail-header-container {
  align-items: center;
  display: flex;
  gap: 12px;
  position: absolute;
  top: 24px;
  left: 36px;
  width: 100%;
}

.wellnessnutritionaldetail-container .text-wrapper-8 {
  color: #000000;
  font-family: var(--regular-12-font-family);
  font-size: var(--regular-12-font-size);
  font-style: var(--regular-12-font-style);
  font-weight: var(--regular-12-font-weight);
  letter-spacing: var(--regular-12-letter-spacing);
  line-height: var(--regular-12-line-height);
  opacity: 0.5;
}

.wellnessnutritionaldetail-container .text-wrapper-9 {
  color: #000000;
  font-family: var(--regular-12-font-family);
  font-size: var(--regular-12-font-size);
  font-style: var(--regular-12-font-style);
  font-weight: var(--regular-12-font-weight);
  letter-spacing: var(--regular-12-letter-spacing);
  line-height: var(--regular-12-line-height);
  white-space: nowrap;
}

.wellnessnutritionaldetail-container .wellnessnutritionaldetail-buttonGoBack {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 40px;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.wellnessnutritionaldetail-container .icon-instance-node-3 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.wellnessnutritionaldetail-container .text-wrapper-10 {
  color: #343a3d;
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.wellnessnutritionaldetail-container .frame-14 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  opacity: 0;
  position: relative;
}

.wellnessnutritionaldetail-container .check-label-instance {
  flex: 0 0 auto !important;
}

.wellnessnutritionaldetail-container .check-label-2 {
  height: 5px !important;
  left: -7670px !important;
  top: -18774px !important;
  width: 7px !important;
}

.wellnessnutritionaldetail-container .rectangle-7 {
  background-color: var(--dark-500);
  height: 36px;
  opacity: 0.15;
  position: relative;
  width: 1px;
}

.wellnessnutritionaldetail-container .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.wellnessnutritionaldetail-container .overlap-2 {
  display: flex;
  padding: 0 32px;
  height: 100%;
}

.wellnessnutritionaldetail-container .rectangle-8 {
  height: 200px;
  width: 100%;
  object-fit: cover;
  position: relative;
  top: 20px;
  border-radius: 15px;
  display: flex;
}

.wellnessnutritionaldetail-container .frame-16 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 70%;
  position: absolute;
  top: 143px;
  width: 340px;
}

.wellnessnutritionaldetail-container .frame-17 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #343a3d33;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 28px;
  z-index: 0;
}

.wellnessnutritionaldetail-container .text-wrapper-11 {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.wellnessnutritionaldetail-container .rectangle-9 {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-18 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-12 {
  color: var(--dark-100);
  font-family: "Red Hat Display", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.wellnessnutritionaldetail-container .frame-20 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-13 {
  color: var(--dark-100);
  font-family: "Red Hat Display", Helvetica;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.wellnessnutritionaldetail-container .frame-21 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.wellnessnutritionaldetail-container .frame-22 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
  margin-top: 40px;
}

.wellnessnutritionaldetail-container .frame-22-summary {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .text-wrapper-14 {
  color: var(--dark-300, #7a7d7f);
  /* SemiBold/UP/12 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
}

.wellnessnutritionaldetail-container .text-wrapper-15 {
  color: var(--dark-500);
  font-family: "Red Hat Display", Helvetica;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.wellnessnutritionaldetail-container .frame-23 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-24 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.wellnessnutritionaldetail-container .h-1 {
  color: var(--corp-500);
  font-family: "Red Hat Display", Helvetica;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  margin: 0px;
  right: 0px;
}

.wellnessnutritionaldetail-container .p {
  color: var(--dark-300);
  font-family: var(--semibold-14-font-family);
  font-size: var(--semibold-14-font-size);
  font-style: var(--semibold-14-font-style);
  font-weight: var(--semibold-14-font-weight);
  letter-spacing: var(--semibold-14-letter-spacing);
  line-height: var(--semibold-14-line-height);
  position: relative;
  white-space: nowrap;
  margin: 0px;
  right: 0px;
}
/* 
    .wellnessnutritionaldetail-container .button-instance {
      align-self: stretch !important;
      display: flex !important;
      flex: 0 0 auto !important;
      width: 100% !important;
    }
    
    .wellnessnutritionaldetail-container .button-2 {
      display: flex !important;
      flex: 1 !important;
      flex-grow: 1 !important;
      height: 48px !important;
      padding: 4px 16px !important;
    } */

.wellnessnutritionaldetail-container .frame-25 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .prenotazioni-min {
  color: var(--dark-500);
  font-family: "Red Hat Display", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.wellnessnutritionaldetail-container .text-wrapper-16 {
  font-weight: 700;
}

.wellnessnutritionaldetail-container .frame-26 {
  background-color: #343a3d0d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 27px 30px;
  flex-direction: column;
  position: static;
  top: 550px;
}

.wellnessnutritionaldetail-container .text-wrapper-17 {
  align-self: stretch;
  color: var(--dark-500);
  font-family: var(--bold-18-font-family);
  font-size: var(--bold-18-font-size);
  font-style: var(--bold-18-font-style);
  font-weight: var(--bold-18-font-weight);
  letter-spacing: var(--bold-18-letter-spacing);
  line-height: var(--bold-18-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.wellnessnutritionaldetail-container .frame-27 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.wellnessnutritionaldetail-container .frame-28 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 298px;
}

.wellnessnutritionaldetail-container .assistenza-1 {
  height: 20.56px !important;
  margin-bottom: -0.56px !important;
  position: relative !important;
  width: 20px !important;
}

.wellnessnutritionaldetail-container .text-wrapper-18 {
  color: var(--dark-500, #222729);
  text-align: center;
  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.wellnessnutritionaldetail-container .oppure {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
}

.wellnessnutritionaldetail-container .frame-29 {
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-19 {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 117.647% */
}

.wellnessnutritionaldetail-container .frame-30 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 32px;
  position: absolute;
  top: 236px;
  width: 612px;
}

.wellnessnutritionaldetail-container .frame-31 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
  top: 270px;
  right: 180%;
}

.wellnessnutritionaldetail-container .frame-32 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .text-wrapper-20 {
  color: var(--dark-500);
  font-family: var(--bold-24-font-family);
  font-size: var(--bold-24-font-size);
  font-style: var(--bold-24-font-style);
  font-weight: var(--bold-24-font-weight);
  letter-spacing: var(--bold-24-letter-spacing);
  line-height: var(--bold-24-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.wellnessnutritionaldetail-container .frame-33 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  top: 6%;
}

.wellnessnutritionaldetail-container .frame-34 {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

.wellnessnutritionaldetail-container .text-wrapper-21 {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.wellnessnutritionaldetail-container .frame-35 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-22 {
  color: var(--dark-500, #222729);
  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  margin: 10px 0px 0px 0px;
}

.wellnessnutritionaldetail-container .frame-36 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
  top: 28%;
}

.wellnessnutritionaldetail-container .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
  left: 57%;
}

.wellnessnutritionaldetail-container .rectangle-10 {
  height: 155px;
  flex-shrink: 0;
  border-radius: 12px;
  background: lightgray 50% / cover no-repeat;
  position: inherit;
  right: 100%;
}

.wellnessnutritionaldetail-container .lorem-ipsum-dolor {
  align-self: stretch;
  color: transparent;
  font-family: "Red Hat Display", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-23 {
  color: #000;

  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.wellnessnutritionaldetail-container .text-wrapper-24 {
  color: var(--dark-500, #222729);
  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  align-self: stretch;
  display: grid;
}

.wellnessnutritionaldetail-container .rectangle-11 {
  align-self: stretch;
  height: 200px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-38 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .text-wrapper-25 {
  color: var(--corp-500, #36b39e);
  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

.wellnessnutritionaldetail-container .frame-39 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-40 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  position: relative;
}

.wellnessnutritionaldetail-container .rectangle-12 {
  align-self: stretch;
  height: 100px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-26 {
  color: var(--dark-500, #222729);

  /* SemiBold/18 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  margin-top: 0;
  margin-bottom: 20px;
}

.wellnessnutritionaldetail-container .frame-42 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-27 {
  color: var(--dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.wellnessnutritionaldetail-container .frame-wrapper {
  align-self: stretch;
  background-color: #35b39e1a;
  border-radius: 8px;
  height: 100px;
  margin-left: -0.33px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .frame-43 {
  height: 60px;
  left: 66px;
  position: absolute;
  top: 20px;
  width: 60px;
}

.wellnessnutritionaldetail-container .text-wrapper-28 {
  align-self: stretch;
  color: var(--dark-500);
  font-family: var(--semibold-14-font-family);
  font-size: var(--semibold-14-font-size);
  font-style: var(--semibold-14-font-style);
  font-weight: var(--semibold-14-font-weight);
  letter-spacing: var(--semibold-14-letter-spacing);
  line-height: var(--semibold-14-line-height);
  position: relative;
}

.wellnessnutritionaldetail-container .frame-44 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  top: 25%;

  margin-top: 40px;
}

.wellnessnutritionaldetail-container .frame-45 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.wellnessnutritionaldetail-container .frame-46 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-29 {
  color: var(--dark-500);
  font-family: var(--bold-18-font-family);
  font-size: var(--bold-18-font-size);
  font-style: var(--bold-18-font-style);
  font-weight: var(--bold-18-font-weight);
  letter-spacing: var(--bold-18-letter-spacing);
  line-height: var(--bold-18-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.wellnessnutritionaldetail-container .text-wrapper-30 {
  color: var(--dark-500, #222729);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.wellnessnutritionaldetail-container .text-wrapper-31 {
  top: 3px;
  color: var(--dark-150, #bdbebf);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
}

.wellnessnutritionaldetail-container .frame-47 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.wellnessnutritionaldetail-container .frame-48 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.wellnessnutritionaldetail-container .text-wrapper-32 {
  color: var(--dark-500, #222729);

  /* SemiBold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.wellnessnutritionaldetail-container .text-wrapper-33 {
  color: var(--dark-500);
  font-family: var(--bold-24-font-family);
  font-size: var(--bold-24-font-size);
  font-style: var(--bold-24-font-style);
  font-weight: var(--bold-24-font-weight);
  letter-spacing: var(--bold-24-letter-spacing);
  line-height: var(--bold-24-line-height);
  margin-top: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  /* display: flex;
      width: 100%; */
}

.wellnessnutritionaldetail-container .button-4 {
  align-items: center;
  background-color: var(--corp-500);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 24px;
  position: relative;
  width: 160px;
}

.wellnessnutritionaldetail-container .text-wrapper-34 {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  position: relative;
  display: flex;
}

.wellnessnutritionaldetail-container .text-wrapper-35 {
  color: #ffffff;
  font-family: "Red Hat Display", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.faq-header-desc {
}

.wellnessnutritionaldetail-container .text-wrapper-36 {
  align-self: stretch;
  color: var(--dark-500);
  font-family: var(--bold-24-font-family);
  font-size: var(--bold-24-font-size);
  font-style: var(--bold-24-font-style);
  font-weight: var(--bold-24-font-weight);
  letter-spacing: var(--bold-24-letter-spacing);
  line-height: var(--bold-24-line-height);
  margin-top: -1px;
  position: relative;
}

.wellnessnutritionaldetail-container .contattaci-su-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .contattaci-su {
  color: var(--dark-500);
  font-family: "Red Hat Display", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.wellnessnutritionaldetail-container .text-wrapper-37 {
  color: var(--dark-500, #222729);

  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.wellnessnutritionaldetail-container .text-wrapper-38 {
  text-decoration: underline;
}

.wellnessnutritionaldetail-container .frame-49 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
  top: 30%;
}

.wellnessnutritionaldetail-container .lorem-ipsum-dolor-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 4px 40px 4px 0px;
  position: relative;
  width: 100%;
}

.wellnessnutritionaldetail-container .lorem-ipsum-dolor-2 {
  color: var(--dark-500, #222729);

  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.spleet-service {
  display: flex;
  flex: 0 0 auto;
  gap: 20%;
  position: relative;
  right: 25%;
}

.gallery-container {
  height: 120px;
  overflow: hidden;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.activity-container {
  height: 225px;
  overflow: hidden;
}

.activity-image {
  width: 100%;
  height: 65%;
  object-fit: cover;
  border-radius: 4px;
}

.activity-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 5px;
}
.wellnessnutritionaldetail-container .frame-31-service {
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  flex-direction: column;
  display: flex;
  width: 217px;
}

.text-wrapper-21-service {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 12px;
  width: 100%;
}
.text-wrapper-21-service .button.button-line {
  float: right;
}

.text-wrapper-14-service {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  white-space-collapse: break-spaces;
}

.icon-favorite {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--dark-150);
  box-shadow: inset 0px -1px 0px #343a3d33;
  left: 925px;
}

.text-wrapper-21-gallery {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  line-height: 15px;
  /* display: flex; */
  top: 3%;
}

.title-gallery {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  line-height: 36px;
  margin-top: 40px;
  top: 3%;
  margin-bottom: 16px;
}

.text-wrapper-21-description {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  line-height: 36px;
  display: flex;
  top: 10%;
  margin-top: 40px;
}

.description-wellness-nutritional-details {
  overflow-wrap: anywhere;
}

.text-wrapper-21-map {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  line-height: 36px;
  display: flex;
  top: 11%;
  margin-top: 40px;

  margin-bottom: 12px;
}

.text-wrapper-21-activity {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  line-height: 36px;
  display: flex;
  top: 20%;
  margin-top: 40px;
}

.text-wrapper-26-activity-desc {
  color: var(--dark-500, #222729);
  position: relative;
  display: flex;
  margin-top: 8px;
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 28px;
}

.image-activity {
  height: 145px;
}

.image-gallery {
  height: 145px;
}

.column-activity {
  max-width: calc(25% - 200px);
  padding: 10px 10px 0px 15px;
  box-sizing: border-box;
  position: relative;
  top: 20%;
  display: inline;
}

.row-activity {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
  top: 20%;
  right: 2%;
}

.wellnessnutritionaldetail-container .frame-34-activity {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin-right: 10px;
}

.wellnessnutritionaldetail-container .frame-34-gallery {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin-right: 10px;
}

.text-wrapper-14-activity {
  color: var(--dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  margin-top: 10px;
}

.objective-activity {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin: 0px;
}

.objective-desc {
  color: var(--dark-300, #7a7d7f);

  /* SemiBold/UP/12 */
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
  margin-bottom: 0px;
}

.wellnessnutritionaldetail-bady-page {
  position: relative;
  margin-right: 40px;
  height: 100%;
  width: -webkit-fill-available;
}

.container-summary {
  margin-top: -114px;
  margin-left: auto;
}

.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
}
.column {
  flex: 1 1 calc(25% - 10px);
  max-width: calc(25% - 10px);
  box-sizing: border-box;
}

.wellnessnutritionaldetail-imageGallery {
  margin-top: 15px;
}

.spleet-image-activity {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1%;
  position: relative;
  width: 100%;
}

.spleet-image-gallery {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.smartPlanDetail-bookNow {
  position: initial;
  display: initial;
}

.button-4-BookNow {
  display: flex;
  width: 160px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--corp-500, #36b39e);
}

.button-4-BookNow .button.button-large {
  width: 100%;
  height: 100%;
  z-index: 100;
}

.column-bookNow {
  max-width: calc(25% - 200px);
  padding: 10px 10px 0px 15px;
  box-sizing: border-box;
  position: relative;
  top: 20%;
  display: inline;
}

.last-row-images {
  width: 100%;
  display: flex;
  gap: 20px;
  position: relative;
  left: 69%;
  top: 9px;
  height: 144.9px;
}

.faq-content {
  position: absolute;
  margin-top: 15%;
}

.container-answer {
  position: inherit;
  top: 30%;
  cursor: pointer;
  width: 100%;
}

.box-answer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  height: 80px;
  width: 100%;
  top: 30%;
}

.line-bookNow {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 100%;
}

.line-bookNow-initial {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 100%;
  top: 26%;
}

.line-bookNow-title {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 77%;
  top: 26%;
}

.column-gallery {
  max-width: calc(25% - 200px);
  padding: 10px 10px 0px 15px;
  box-sizing: border-box;
  position: relative;
  top: 20%;
  display: inline;
}

.row-gallery {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
  top: 20%;
  right: 2%;
}

.box-response {
  margin-bottom: 16px;
  margin-top: 8px;
}

.page-bottom {
  display: flex;
  position: relative;
  justify-content: end;
  top: 3680px;
  margin-bottom: 10px;
}

.everywhere-name {
  margin-bottom: 20px;
  margin-right: 10px;
  color: #343a3d;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  opacity: 0.7;
}

.everywhere-email {
  margin-bottom: 20px;
  margin-right: 10px;
  color: #343a3d;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  opacity: 0.7;
}

.everywhere-privacy-policy {
  margin-bottom: 20px;
  margin-right: 10px;
  color: #343a3d;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  opacity: 0.7;
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
}

.service-container > div {
  flex-basis: calc(
    33.33% - 10px
  ); /* Calcola la larghezza massima per 3 elementi con margine */
  margin: 5px; /* Aggiungi un margine tra gli elementi */
}

.flex-container-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  flex-wrap: wrap;
}

.flex-container-activity {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  flex-wrap: wrap;
}

.flex-container-bookNow {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.service-title {
  color: var(--dark-300, #7a7d7f);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.service-subtitle {
  color: var(--dark-500, #222729);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
/* 
    .smart-plan {
      position: relative;
    } */

.gallery-overlay {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.gallery-overlay .gallery {
  position: relative;
  width: 1013px;
  height: 520px;
  overflow: hidden;
  text-align: center;
}

.gallery-image-large {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: scale-down;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 30px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.prev-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  left: 0px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  right: 0px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: white; /* Colore verde acqua */
  border-radius: 50%; /* Per rendere il pallino */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  font-size: 20px;
  color: #00ced1; /* Colore del testo bianco */
  transition: background-color 0.3s ease;
}

.prev-button {
  left: 10px;
  width: 50px;
  height: 50px;
}

.next-button {
  right: 10px;
  width: 50px;
  height: 50px;
}

.arrow-gallery {
  width: 70px;
  height: 25px;
}

.wellnessbody-details-sessionscontainer {
  display: flex;
  padding: 24px 28px 28px 28px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--corp-100, #d7f0ec);
}

.wellnessbody-details-sessionscontainer-txt1 {
  align-self: stretch;
  color: var(--dark-500, #222729);
  text-align: center;

  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px; /* 150% */
}

.wellnessbody-details-sessionscontainer-txt2 {
  align-self: stretch;
  color: var(--dark-500, #222729);
  text-align: center;

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.wellness-image2-user-provider-nutrition {
  width: 140px;
  height: 140px;
  position: absolute;
  object-fit: cover;
  margin-left: 30px;
  border: 2px solid #fff;
  top: 48px;
}

.wellness-details-image-container-provider {
  width: 100%;
  position: relative;
  align-items: center;
}

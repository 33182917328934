.paymentsuccess-wrapper {
  background: var(--corp-500, #36b39e);
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.paymentsuccess-content {
  display: flex;
  width: 420px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  border-radius: 16px;
  background: var(--White, #fff);
}

.paymentsuccess-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  align-self: stretch;
}

.paymentsuccess-frame-firstsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.paymentsuccess-frame-firstsection-iconcontainer {
  display: flex;
  width: 60px;
  height: 60px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 52px;
  background: var(--corp-50, #ebf7f5);
}

.paymentsuccess-frame-firstsection-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.paymentsuccess-frame-firstsection-subtitle {
  color: var(--dark-350, #646869);
  text-align: center;

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.paymentsuccess-frame-firstsection-firsttext {
  align-self: stretch;
  color: var(--corp-700, #206b5f);
  text-align: center;

  /* Bold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}
.paymentsuccess-frame-firstsection-secondtext {
  align-self: stretch;
  color: var(--dark-350, #646869);
  text-align: center;

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.paymentsuccess-frame-thirdsection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.paymentsuccess-frame-secondsection {
  border-radius: 12px;
  width: 100%;
}

.paymentsuccess-frame-thirdsection-firstbutton {
  width: 100%;
  display: flex;
  height: 48px;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: var(--corp-150, #c3e8e2);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  cursor: pointer;
}
.paymentsuccess-frame-thirdsection-secondbutton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  padding: 4px 0;

  border: 1px solid var(--dark-100, #d3d4d4);
  background: var(--White, #fff);
  cursor: pointer;
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.2) inset;
  height: 48px;
}
.paymentsuccess-frame-thirdsection-thirdbutton {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.payment-everywhere-logo {
  position: absolute;
  left: 0px;
  margin-top: 20px;
  margin-left: 25px;
}

.paymentfailed-buttontext {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

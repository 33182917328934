.check-label {
  align-items: center;
  display: flex;
  position: relative;
  width: fit-content;
}

.check-label .switch-instance {
  min-width: 44px !important;
  width: unset !important;
}

.check-label .check-2 {
  height: 20px;
  min-width: 20px;
  position: relative;
}

.check-label .rectangle-5 {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
}

.check-label .label-text-wrapper {
  align-items: flex-start;
  display: flex;
  padding: 0px 0px 0px 4px;
  position: relative;
  width: fit-content;
}

.check-label .label-text {
  color: var(--dark-500);

  font-size: 14px;
  font-weight: var(--font-weight-medium);

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.check-label.type-radio {
  gap: 4px;
}

.check-label.type-switch {
  gap: 8px;
}

.check-label.type-check {
  gap: 4px;
}

.check-label .check {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-radius: 4px;
}

.check-label .disabled-on.check {
  border-color: var(--dark-50);
}

.check-label .disabled-off.check {
  border-color: var(--dark-500);
}

.check-label .disabled-1-on {
  opacity: 0.2;
}

.check-label .check-2.disabled-on .rectangle-5 {
  border-color: var(--dark-50);
}

.check-label .check-2.switch .rectangle-5 {
  border-radius: 4px;
  height: 20px;
  width: 20px;
}

.check-label .check-2.check .rectangle-5 {
  border-radius: 4px;
  height: 20px;
  width: 20px;
}

.check-label .check-2.radio .rectangle-5 {
  border-radius: 20px;
  height: 22px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 22px;
}

.check-label .check-2.disabled-off .rectangle-5 {
  border-color: var(--dark-200);
  box-shadow: 0px 1px 1px #343a3d1a;
}

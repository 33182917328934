.modal-booking-wellness .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
}

.rectangle-5-wellness {
  background-color: var(--dark-50);
  height: 1px;
  position: relative;
  margin: 20px 0px 0px 0px;
}

.modal-booking-wellness .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  overflow-x: hidden;
}


.description-modal-wellness-all {
  color: #222628;
  text-align: left;
  vertical-align: text-top;
  font-size: 18px;
  position: relative;
  font-family: Red Hat Display;
  line-height: 18px;
  border-style: hidden;
  text-transform: uppercase;
}

.descr-modal {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.total-credit-modal-wellness {
  color: #36b39e;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 1000;
  line-height: 20px;
}

.credit-available {
  display: flex;
  position: relative;

  top: 10px;
}
.total-credit-icon-modal-wellness {
  font-size: 10px;
}

.cost-service {
  display: flex;
  width: 100%;
}

.cost-service-text {
  width: 50%;
  color: #222628;
  text-align: left;
  vertical-align: text-top;
  font-size: 20px;
  font-family: Red Hat Display;
  line-height: 20px;
  border-style: hidden;
  font-weight: 1000;
}

.cost {
  display: flex;
  justify-content: end;
  width: 50%;
  color: #36b39e;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 1000;
  line-height: 20px;
}

.cost-service-text-icon {
  position: relative;
  bottom: 2px;
}

.info-not-enough-credits {
  width: auto;
  background-color: #fffaf0;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.info-not-enough-credits-text {
  padding: 10px;
}

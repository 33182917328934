.service-container-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.service-container-row-element {
  display: flex;
  flex-direction: column;
}

.office-coworking-services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.office-coworking-service-title {
  color: var(--dark-300, #7a7d7f);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.office-coworking-booking-check-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.office-coworking-booking-check-section-datepicker {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

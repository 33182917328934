.mn-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 993;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}

.smartplan-filtermodal {
  display: flex;
  width: 580px;
  height: fit-content;
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}

.smartplan-filtermodal-header {
  display: flex;
  padding: 20px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(
    --gradients-corp,
    linear-gradient(225deg, #36b39e 0%, #108aa3 100%)
  );
}

.smartplan-filtermodal-header-text {
  height: max-content;
  color: var(--white, #fff);
  /* Bold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  width: 100%;
}

.smartplan-filtermodal-header-iconclose {
  height: max-content;
  padding-top: 6px;
  width: fit-content;
  cursor: pointer;
}

.smartplan-filtermodal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 36px;
  align-self: stretch;
  height: 100%;
  align-items: flex-end;
}

.smartplan-filtermodal-content-dropdowns {
  display: flex;
  align-items: flex-start;
  gap: 28px;
  align-self: stretch;
}

.smartplan-filtermodal-content-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.smartplan-filtermodal-text-select {
  color: rgba(128, 128, 128, 0.464);
  font-weight: 370;
}

.smartplan-filtermodal-text {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.smartplan-filtermodal-text-white {
  color: white;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.smartplan-filtermodal-content-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.smartplan-filtermodal-content-toggle-button {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  white-space: nowrap;
}

.smartplan-filtermodal-content-range-slider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.sa .smartplan-filtermodal-footer {
  display: flex;
  padding: 28px 32px;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px 16px 16px;
}

.smartPlan-filtermodal-content-range-slider-text {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.smartPlan-filtermodal-content-buttons {
  display: flex;
  gap: 15px;
  box-align: center;
}

.smartPlan-filtermodal-content-vector-confirm {
  padding-top: 5px;
}

.retrivePassword {
  background-color: #1b5a4f80;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.retrivePassword-container {
  background-color: #1b5a4f80;
  background-image: url(../../img/SfondoLogin.svg);
  background-position: 50% 50%;
  background-size: cover;
  border: 1px none;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.retrivePassword-logo {
  left: 40px;
  position: fixed;
  top: 36px;
}

.retrivePassword-content {
  align-items: flex-start;
  background-color: var(--global-colors-ui-white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 40px;
  height: 383px;
  width: 364px;
}

.retrivePassword-form-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.retrivePassword-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: fit-content;
}

.retrivePassword-form-instance {
  gap: 12px !important;
  width: 360px !important;
}

.retrivePassword-form-title {
  font-size: var(--bold-28-font-size) !important;
  font-style: var(--bold-28-font-style) !important;
  font-weight: var(--bold-28-font-weight) !important;
  letter-spacing: var(--bold-28-letter-spacing) !important;
  line-height: var(--bold-28-line-height) !important;
}

.retrivePassword-form-description {
  color: #161938 !important;
}

.retrivePassword-input-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 360px;
}

.retrivePassword-input-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: fit-content;
}

.retrivePassword-icon-instance {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.username-field {
  border-radius: 6px;
  border: 1px solid var(--dark-150, #bdbebf);
  background: #fff;
  display: flex;
  padding: 0px 12px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}
.retrivePassword-up-span {
  color: var(--dark-500, #222729);
  /* Bold/28 */
  font-family: Red Hat Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 135.714% */
  width: 360px;
}

.retrivePassword-desc-span {
  color: #161938;
  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.email-span {
  color: var(--dark-500, #222729);
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.password-span {
  color: var(--dark-500, #222729);
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.retrivePassword-button-wrapper {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.retrivePassword-button-wrapper .button {
  border-radius: 6px;
  background: var(--corp-500, #36b39e);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  width: 110%;
  height: 48px;
}

.retrivePassword-button-wrapper .button-text {
  color: #fff;
}

.retrivePassword-button-instance {
  align-self: stretch !important;
  width: unset !important;
}

.retrivePassword-button {
  flex: 1 !important;
  height: 48px !important;
  padding: 4px 16px !important;
  width: unset !important;
}

.retrivePassword-login {
  align-self: stretch;
  color: transparent;

  font-size: 14px;
  font-weight: var(--font-weight-light);

  position: relative;
  text-align: center;
  white-space: nowrap;
}

.retrivePassword-span {
  color: #222628;

  font-size: var(--font-size-md);
  font-style: var(--regular-14-font-style);
  font-weight: var(--font-weight-light);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
}

.retrivePassword-text-3 {
  color: #35b39e;
  font-weight: var(--font-weight-semi-bold);
  cursor: pointer;
}

.retrivePassword-footer-bottom {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 96%;
  bottom: 20px;
  position: fixed;
}

.retrivePassword-text-4 {
  color: var(--global-colors-ui-white);

  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);

  margin-top: -1px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.retrivePassword-text-5 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;
}

.retrivePassword-text-6 {
  color: var(--global-colors-ui-white);

  font-size: 12px;
  font-weight: var(--font-weight-light);

  margin-top: -1px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .forty-ic-tik {
  background-image: url(https://c.animaapp.com/iMcFVRUa/img/20-ic-tik-2.svg) !important;
}

.frame {
  align-items: center;
  background-color: var(--corp-50);
  border-radius: 52px;
  display: flex;
  gap: 4px;
  height: 60px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 60px;
}

.recharge-employee-modal-wrapper {
  display: flex;
  width: 600px;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  border-radius: 16px;
  background: #fff;
  z-index: 999;
  left: 35%;
  top: 5%;
}

.recharge-employee-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(
    --gradients-corp,
    linear-gradient(225deg, #36b39e 0%, #108aa3 100%)
  );
}

.recharge-employee-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  height: 100%;
  padding: 30px;
  padding-bottom: 0;
  width: calc(100% - 60px);
}

.recharge-employee-modal-header-text {
  color: var(--White, #fff);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  display: flex;
  gap: 5px;
  align-items: center;
}

.recharge-employee-modal-header-text .ant-btn {
  display: flex;
  align-items: center;
  background: transparent !important;
}

.icon-recharge-employee {
  position: relative;
  padding: 0px 5px 0px 0px;
  top: 3px;
}

.recharge-employee-modal-content-firstsection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.recharge-employee-modal-content-firstsection-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.recharge-employee-modal-content-firstsection-section-title {
  color: var(--White, #000000);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.recharge-employee-modal-content-firstsection-section-title-credits {
  color: #36b39e;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 140% */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.recharge-employee-modal-content-firstsection-section-title-minus-credits {
  color: #000000;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.recharge-employee-modal-content-secondsection {
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.recharge-employee-modal-content-secondsection-switch {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.recharge-employee-modal-content-secondsection-switch-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.recharge-employee-modal-content-empty-selection {
  width: 100%;
  height: 120px;
  background-color: #f8f9f9;
}

.recharge-employee-modal-content-empty-selection-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 5px;
}

.recharge-employee-modal-content-empty-selection-titles-first {
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.recharge-employee-modal-content-empty-selection-titles-second {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.recharge-employee-modal-content-creditstosend-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.recharge-employee-modal-content-creditstosend-section-text-one {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.recharge-employee-modal-content-creditstosend-section-text-two {
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #36b39e;
}

.recharge-employee-modal-content-creditstosend-section-text-two-wrong {
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #f26549;
}

.recharge-employee-modal-content-creditstosend-selectall {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.recharge-employee-modal-content-creditstosend-summary-text {
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.recharge-employee-modal-content-creditstosend-summary-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.recharge-employee-modal-content-creditstosend-summary-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.recharge-employee-modal-content-creditstosend-summary-card-textone {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.recharge-employee-modal-content-creditstosend-summary-card-texttwo {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.recharge-employee-modal-content-buttons {
  display: flex;
  gap: 15px;
  width: calc(100% - 40px);
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.recharge-employee-modal-content-buttons-2 {
  display: flex;
  gap: 15px;
  width: calc(100% - 40px);
  padding: 20px;
  align-items: center;
  justify-content: flex-end;
}

.recharge-employee-modal-text-select {
  color: rgba(128, 128, 128, 0.464);
  font-weight: 370;
}

.recharge-employee-modal-content-vector-confirm {
  padding-top: 5px;
}

.recharge-employee-modal-text-white {
  color: white;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.recharge-employee-modal-footer-text {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.wellnessbody-content {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
    gap: 22px;
    min-height: 90vh;
  }
  
  .wellnessbody-content .element-ic-laptop-instance {
    min-width: 20px !important;
    width: unset !important;
  }
  
  .wellnessbody-content .twenty-ic-laptop-instance {
    border-color: var(--corp-500) !important;
  }
  
  .wellnessbody-content .button-instance {
    left: 458px !important;
    position: absolute !important;
    top: 24px !important;
  }
  
  .wellnessbody-content .text-wrapper-18 {
    color: var(--red-500);
  
    font-size: var(--font-size-sm);
    font-style: var(--bold-12-font-style);
    font-weight: var(--font-weight-semi-bold);
  
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .wellnessbody-content .icon-instance-node-3 {
    height: 20px !important;
    left: 2px !important;
    position: absolute !important;
    top: 2px !important;
    width: 20px !important;
  }
  
  .wellnessbody-content .element-ic-laptop-2 {
    left: 2px !important;
    top: 2px !important;
  }
  
  .wellnessbody-content .element-ic-laptop-3 {
    border-color: var(--global-colors-ui-white) !important;
  }
  
  .wellnessbody-activitytail-container {
    width: 100%;
  }
  
  .wellnessbody-activitytail-container-results {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 28px;
  }
  
  .wellnessbody-activitytail-container-row {
    white-space: nowrap;
    display: flex;
    gap: 28px;
    width: 100%;
    padding-bottom: 24px;
  }
  
  .wellnessbody-topbar-title-iconplace {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 4px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    background: rgba(50, 179, 154, 0.2);
  }
  
  .wellnessbody-topbar {
    margin-top: 20px;
    gap: 16px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  
  .wellnessbody-topbar-title {
    color: var(--dark-500, #222729);
    /* Bold/24 */
    font-family: Red Hat Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  
  .wellnessbody-topbar-title-iconplace-icon {
    width: 20px;
    height: 20px;
  }
  
  .wellnessbody-activitytail-container-row-button {
    display: flex;
    width: 60px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--brd-section, rgba(34, 39, 41, 0.12));
    color: var(--corp-500, #36b39e);
    /* Bold/14 */
    font-family: Red Hat Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
  
  .wellnessbody-activitytail-container-title {
    color: var(--dark-100, #343a3d);
    /* SemiBold/18 */
    font-family: Red Hat Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 24px;
  }
  
  .wellnessbody-activitytail-transparent-container {
    border: 1px solid;
    border-color: var(--brd-section);
    border-radius: 12px;
    box-shadow: var(--shadow-section);
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    opacity: 0;
    cursor: default !important;
  }
  
  .wellnessbody-activitytail-results-bar {
    justify-content: space-between;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  
  .wellnessbody-activitytail-results-title {
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  
  .wellnessbody-activitytail-results-title-one {
    color: #000;
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  
  .wellnessbody-activitytail-results-title-two {
    color: #000;
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  .wellnessbody-activitytail-results-order {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .wellnessbody-activitytail-results-order-text {
    color: #000;
    font-family: Red Hat Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
  
  .wellnessbody-activitytail-results-order-selection {
    cursor: pointer;
    display: flex;
  }
  
  .wellnessbody-activitytail-results-order-selection-text {
    color: #000;
    font-family: Red Hat Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  
  .wellnessbody-activitytail-results-order-selection-vector {
    padding-top: 5px;
  }
  
  .wellnessbody-myorder-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .wellnessbody-ordersuccess-container {
    display: flex;
    padding: 12px 12px 12px 20px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: var(--corp-50, #ebf7f5);
  }
  
  .wellnessbody-ordersuccess-textcontainer {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }
  
  .wellnessbody-ordersuccess-text {
    color: var(--dark-500, #222729);
  
    /* SemiBold/14 */
    font-family: Red Hat Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  
  .wellnessbody-ordersuccess-icon {
    cursor: pointer;
  }
  
  .wellnessbody-myorderempty-container {
    display: flex;
    padding: 60px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--dark-50, #e9e9ea);
  }
  
  .wellnessbody-myorderempty-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .wellnessbody-myorderempty-secondtext {
    width: 300px;
    color: var(--dark-350, #646869);
    text-align: center;
  
    /* Regular/16 */
    font-family: Red Hat Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
  .wellnessbody-myorderempty-firsttext {
    color: var(--dark-500, #222729);
  
    /* SemiBold/20 */
    font-family: Red Hat Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
  }
  
  .wellnessbody-myorderempty-icon {
    opacity: 20%;
  }
  
  .wellnessbody-bar {
    display: flex;
    flex-direction: row;
  }
  .wellnessbody-switchbar-tabs {
    display: flex;
    padding: 2px;
    gap: 2px;
    border-radius: 6px;
    background: var(--dark-50, #e9e9ea);
    width: fit-content;
    height: 30px;
    font-size: 14px;
    font-style: var(--bold-14-font-style);
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: var(--bold-14-letter-spacing);
    line-height: var(--bold-14-line-height);
    white-space: nowrap;
    align-items: center;
  }
.mn-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 993;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
}

.mn-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.8); /* Sfondo nero opaco */
  display: flex;
  align-items: center;
  justify-content: center;
}

.mn-modal__dialog {
  width: 540px;
  height: 590px;
  /* Aggiungi gli altri stili necessari */
}

.mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
}

.mn-modal__dialog-title {
  font-family: var(--global-font-medium);
}

.mn-modal__dialog-text {
  margin: 0;
  white-space: break-spaces;
}

.mn-modal__dialog-tag {
  display: inline;
  justify-content: space-between;
}

.mn-modal__dialog-tag .mn-tag {
  margin-left: 4px;
}

.mn-modal .mn-modal__dialog--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-header {
  position: relative;
  display: flex;
  padding: 20px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(
    --gradients-corp,
    linear-gradient(225deg, #36b39e 0%, #108aa3 100%)
  );
}

.mn-modal__dialog-header__skipmodal {
  position: relative;
  display: flex;
  padding: 20px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(
    --gradients-corp,
    linear-gradient(225deg, #f2c261 0%, #f36d52 100%)
  );
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-header .mn-modal__dialog-close {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  z-index: 1;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div {
  font-size: 20px;
  font-family: var(--global-font-semi-bold);
  line-height: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div
  + div {
  margin-top: var(--global-spacing-xs);
  margin-bottom: 0;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 32px 32px 0px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  overflow-x: hidden;
}

.mn-modal__dialog-body {
  flex: 1; /* Flessibile per occupare lo spazio rimanente */
  overflow: auto; /* Abilita lo scorrimento se necessario */
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-body[data-scrollable="true"]
  .mn-modal__dialog-body--scrollable {
  height: 200px;
  overflow-y: scroll;
}

.mn-modal__dialog-footer {
  padding: 24px 32px; /* Aggiungi il padding inferiore */
  border-top: 1px solid var(--global-colors-ink-lighter); /* Aggiungi una linea superiore */
  display: flex;
  justify-content: flex-end;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-footer,
.mn-modal .mn-modal__dialog .mn-modal__dialog-footer-back {
  padding: 28px 32px;
  display: flex;
  justify-content: flex-end;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-footer-back
  > .button--secondary:first-child {
  margin-right: auto;
}

.mn-modal .mn-modal__dialog > .mn-modal__dialog-header:first-of-type {
  padding: 0;
}

.mn-modal__columns {
  display: grid;
  grid-template-columns: 1fr 40%;
}

.mn-modal__right-column {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(
    180deg,
    #007adb 0%,
    /* Not in design system color palette. */ var(--global-colors-ui-primary)
      30%
  );
  color: var(--global-colors-ui-white);
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__right-column
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div
  + div {
  color: var(--global-colors-ink-lighter);
}

.image-import {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.image-import > div:first-child {
  min-width: 274px;
}

.button-ok {
  display: flex;
  height: 40px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 72px;
  height: 40px;
}

.button-ok .button {
  width: 100%;
  height: 100%;
  background: var(--corp-500, #36b39e);
}

.button-cancel {
  /* display: flex;
  height: 40px; */
  /* padding: 4px 12px; */
  /* justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 82px;
  height: 40px; */
  margin-right: 15px;
}

.button-cancel .button {
  width: 100%;
  height: 100%;
  background: white;
}

.title-modal {
  color: var(--White, #fff);

  /* Bold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.modal-body-dynamic {
  height: fit-content;
}

.input {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}

.input .class {
  align-self: stretch !important;
  flex: 1 !important;
  height: unset !important;
  padding: 0px 12px !important;
  width: unset !important;
}

.input .class-2 {
  flex: 1 !important;
  height: 48px !important;
  padding: 0px 12px !important;
  width: unset !important;
}

.input .class-3 {
  flex: 1 !important;
  width: unset !important;
}

.input .class-4 {
  padding: 0px 8px !important;
}

.input .class-5 {
  padding: 0px 6px !important;
}

.input .default-88 {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.input.l {
  height: 40px;
}

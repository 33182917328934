.home-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 40px;
}

.footer-bottom {
  position: relative;
  top: 12%;
}

.home-content .frame-15 {
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgba(32, 177, 207, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  height: 360px;
  min-width: 1056px;
  overflow: hidden;
  position: relative;
}

.home-content .welcome-image {
  background-image: url(../../img/risorsa-7300-1.png);
  height: 364px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  background-size: cover;
  border: 1px solid;
  border-color: #d3d4d4;
  border-radius: 20px;
}

.home-content .welcome-text {
  color: var(--global-colors-ui-black);
  font-size: 20px;
  font-weight: 600;
  left: 48px;
  position: absolute;
  top: 36px;
  width: 360px;
}

.home-content .frame-16 {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.home-content .frame-17 {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.home-content .text-wrapper-6 {
  color: var(--dark-100, #343a3d);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 1000;
  line-height: 24px; /* 133.333% */
}

.home-content .text-wrapper-7 {
  color: var(--dark-500);

  font-size: 14px;
  font-weight: var(--font-weight-medium);

  opacity: 0.5;
  position: relative;
  width: fit-content;
}

.home-content .frame-18 {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex-direction: column;
}

.home-content .frame-19 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--dark-50);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.home-content .frame-20 {
  align-items: center;
  display: flex;
  gap: 12px;
  position: relative;
  width: fit-content;
}

.home-content .frame-21 {
  height: 28px;
  min-width: 28px;
  position: relative;
}

.home-content .text-wrapper-8 {
  color: var(--dark-500);

  font-size: 16px;
  font-weight: var(--font-weight-medium);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .text-wrapper-9 {
  color: var(--corp-500);

  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .frame-22 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--dark-50);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.home-content .frame-23 {
  align-items: center;
  background-color: var(--global-colors-ui-white) 4c;
  border: 1px solid;
  border-color: #d3d4d4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 30px;
  justify-content: center;
  margin-bottom: -1px;
  padding: 4px;
  position: relative;
  width: 30px;
}

.home-content .text-wrapper-10 {
  color: var(--dark-500);

  font-size: 14px;
  font-weight: var(--font-weight-heavy);

  position: relative;
  width: fit-content;
}

.home-content .frame-24 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--dark-50);
  display: flex;
  gap: 12px;
  padding: 20px;
  position: relative;
}

.home-content .frame-25 {
  align-items: center;
  background-color: var(--global-colors-ui-white) 4c;
  border: 1px solid;
  border-color: #d3d4d4;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 28px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 28px;
}

.home-content .text-wrapper-11 {
  color: var(--dark-500);

  font-size: 14px;
  font-weight: var(--font-weight-heavy);

  margin-top: -0.5px;
  position: relative;
  width: fit-content;
}

.home-content .ellipse {
  height: 30px;
  left: 20px;
  position: absolute;
  top: 0px;
  width: 16px;
}

.home-content .frame-26 {
  display: flex;
  flex-direction: column;
  gap: 28px;
  position: relative;
  padding: 0;
  background-color: white;
}

.home-content .frame-27 {
  display: inline-flex;
  gap: 28px;
  position: relative;
  flex-direction: initial;
}

.home-content .frame-28 {
  background-color: var(--blue-50);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
}

.home-content .frame-28-myService {
  background-color: var(--blue-50);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
  width: 20%;
}

.home-content .frame-29 {
  align-items: center;
  background-color: var(--global-colors-ui-white);
  border-radius: 40px;
  box-shadow: 0px 1px 1px #343a3d1a;
  display: flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;
}

.home-content .element-ic-laptop-instance {
  min-width: 20px !important;
  width: unset !important;
}

.home-content .twenty-ic-laptop-instance {
  border-color: var(--corp-500) !important;
}

.home-content .text-wrapper-12 {
  align-self: stretch;
  color: var(--dark-500);

  font-size: 16px;
  font-weight: var(--font-weight-bold);
  height: 24px;

  position: relative;
  white-space: nowrap;
}

.home-content .text-wrapper-13 {
  align-self: stretch;
  color: var(--dark-500);

  font-size: 14px;
  font-weight: var(--font-weight-medium);
  height: 10px;

  opacity: 0.5;
  position: relative;
  white-space: nowrap;
}

.home-content .frame-30 {
  background: var(--blue-50, #e7f7fa);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
}

.home-content .frame-380 {
  background: var(--Red-100, #fce0db);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
}

.home-content .icon-label {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.home-content .frame-31 {
  background-color: var(--dark-25);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
}

.home-content .frame-32 {
  background-color: #f264481a;
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
}

.fram-28 {
  background: var(--corp-50, #ebf7f5);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  cursor: pointer;
}

.home-content .frame-33 {
  background-color: #ffcb661a;
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 8px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
}

.home-content .mio-team {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  flex: 1;
  height: 212px;
  min-width: 514px;
  overflow: hidden;
  position: relative;
}

.home-content .mio-team-2 {
  padding: 23px;
  color: var(--dark-500);
  font-size: 20px;
  font-weight: var(--font-weight-medium);
  width: 100%;
}

.home-content .mio-team-2 .button {
  float: right;
  margin-right: 46px;
}

.home-content .frame-34 {
  display: flex;
  flex-direction: column;
  left: 28px;
  position: absolute;
  top: 64px;
  width: fit-content;
}

.home-content .text-wrapper-14 {
  color: var(--dark-500);

  font-size: var(--font-size-xxl);
  font-style: var(--bold-20-font-style);
  font-weight: var(--font-weight-semi-bold);

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .text-wrapper-15 {
  color: var(--dark-300);

  font-size: var(--font-size-md);
  font-style: var(--regular-14-font-style);
  font-weight: var(--font-weight-light);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .button-instance {
  left: 458px !important;
  position: absolute !important;
  top: 24px !important;
}

.home-content .frame-35 {
  display: flex;
  justify-content: space-between;
  left: 28px;
  position: absolute;
  top: 136px;
  width: 458px;
}

.home-content .user-instance {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  width: 48px !important;
  z-index: 10 !important;
}

.home-content .user-2 {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  width: 48px !important;
  z-index: 9 !important;
}

.home-content .user-3 {
  background-image: url(../../img/user-3.png) !important;
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  min-width: 48px !important;
  position: relative !important;
  width: unset !important;
  z-index: 8 !important;
}

.home-content .user-4 {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  width: 48px !important;
  z-index: 7 !important;
}

.home-content .user-5 {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  width: 48px !important;
  z-index: 6 !important;
}

.home-content .user-6 {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  width: 48px !important;
  z-index: 5 !important;
}

.home-content .user-7 {
  background-image: url(../../img/user-2.png) !important;
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  min-width: 48px !important;
  position: relative !important;
  width: unset !important;
  z-index: 4 !important;
}

.home-content .user-8 {
  background-image: url(../../img/user-1.png) !important;
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  min-width: 48px !important;
  position: relative !important;
  width: unset !important;
  z-index: 3 !important;
}

.home-content .user-9 {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  width: 48px !important;
  z-index: 2 !important;
}

.home-content .user-10 {
  background-image: url(../../img/user.png) !important;
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  min-width: 48px !important;
  position: relative !important;
  width: unset !important;
  z-index: 1 !important;
}

.home-content .user-11 {
  border: 2px solid !important;
  border-color: var(--global-colors-ui-white) !important;
  height: 48px !important;
  margin-left: -7px !important;
  width: 48px !important;
  z-index: 0 !important;
}

.home-content .ordini-instance {
  flex: 1 !important;
  height: 212px !important;
  min-width: 514px !important;
  width: unset !important;
}

.home-content .ordini-3 {
  top: 138px !important;
  width: 458px !important;
}

.home-content .frame-36 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.home-content .text-wrapper-16 {
  color: var(--corp-500);

  font-size: var(--font-size-md);
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);

  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.home-content .frame-37 {
  align-self: stretch;
  display: flex;
  gap: 28px;
  position: relative;
}

.home-content .frame-38 {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.home-content .rectangle-3 {
  align-self: stretch;
  height: 100px;
  min-width: 333.33px;
  object-fit: cover;
  position: relative;
}

.home-content .frame-39 {
  height: 28px;
  right: 16px;
  position: absolute;
  top: 16px;
  width: 28px;
}

.home-content .frame-wrapper {
  align-self: stretch;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  padding: 20px 24px 24px;
  position: relative;
}

.home-content .frame-40 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.home-content .frame-41 {
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.home-content .text-wrapper-17 {
  color: var(--dark-500);
  font-size: var(--font-size-md);
  font-style: var(--regular-14-font-style);
  font-weight: var(--font-weight-light);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .text-wrapper-18 {
  color: var(--red-500);

  font-size: var(--font-size-sm);
  font-style: var(--bold-12-font-style);
  font-weight: var(--font-weight-semi-bold);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .frame-42 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

.home-content .frame-43 {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.home-content .text-wrapper-19 {
  align-self: stretch;
  color: var(--dark-500);

  font-size: var(--font-size-lg);
  font-style: var(--bold-16-font-style);
  font-weight: var(--font-weight-semi-bold);

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.home-content .da-a-pers {
  color: var(--dark-500);

  font-size: var(--font-size-sm);
  font-style: var(--regular-UP-12-font-style);
  font-weight: var(--font-weight-light);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .frame-44 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}

.home-content .rectangle-4 {
  align-self: stretch;
  height: 100px;
  min-width: 333.33px;
  position: relative;
}

.home-content .frame-45 {
  align-items: center;
  display: flex;
  gap: 16px;
  left: 312px;
  position: absolute;
  top: 20px;
  width: fit-content;
}

.home-content .h-1 {
  color: #343a3d;

  font-size: 24px;
  font-weight: var(--font-weight-semi-bold);

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-content .frame-46 {
  border-radius: 6px;
  height: 32px;
  min-width: 32px;
  opacity: 0.7;
  position: relative;
}

.home-content .rectangle-wrapper {
  height: 20px;
  left: 6px;
  position: relative;
  top: 6px;
  width: 20px;
}

.home-content .rectangle-5 {
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.home-content .icon-instance-node-3 {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.home-content .element-ic-laptop-2 {
  left: 2px !important;
  top: 2px !important;
}

.home-content .element-ic-laptop-3 {
  border-color: var(--global-colors-ui-white) !important;
}

.advice-text {
  color: var(--dark-100, #343a3d);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 1000;
  line-height: 24px; /* 133.333% */
  padding-bottom: 20px;
}

.number-initial-icon-row {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid var(--Dark-100, #d3d4d4);
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 1000;
  line-height: normal;
}

.icon-initial-icon-row {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: var(--Corp-250, #9bd9ce);
  display: flex;
}

.text-initial-row {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  /* width: 25%; */
  padding: 7px 0px 0px 15px;
}

.initial-icon-row {
  display: flex;
}

.row-card {
  display: flex;
  flex-wrap: nowrap;
}

.tag-row {
  padding-left: 15px;
  padding-top: 7px;
}

.tag-row .table-tag-active {
  display: flex;
  white-space: nowrap;
  width: fit-content;

  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  background: var(--orange-80, #fdebd8);
}

.navigate-row {
  color: var(--Corp-500, #36b39e);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* padding: 7px 0px 0px 15px; */
  /* width: 100%; */
  justify-content: end;
  display: flex;
  cursor: pointer;

  padding: 7px 15px 0 0;
  margin-left: auto; /* Posiziona il contenuto alla fine della row */
  order: 1;
}

.first-card .ant-card {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.second-card .ant-card {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.third-card .ant-card {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

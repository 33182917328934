.officecoworking-purchase-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 36px;
  align-self: stretch;
  height: 100%;
  align-items: flex-start;
}

.officecoworking-purchaseprice {
  color: var(--corp-500);
  font-family: "Red Hat Display", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  margin: 0px;
  right: 0px;
}

.officecoworking-purchasetitleprice {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.officecoworking-filtermodal-content-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  box-align: center;
  width: 100%;
}

.officecoworking-purchasemodal-notenoughtcredits {
  width: "100%";
  background-color: #fffaf0;
  padding: 10px;
  border-radius: 6px;
  width: calc(100% - 20px);
}

.officecoworking-purchasemodal-notenoughtcredits {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.officecowrking-purchasemodal-text {
  color: black;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

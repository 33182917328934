.tag {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  height: 10px;
  padding: 8px 8px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
}

.tag-reset {
  display: flex;
  height: 10px;
  width: fit-content;
  align-items: flex-start;
  padding: 8px 8px 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  border: 1px solid var(--dark-80, #cdcdd6);
  background: var(--white, #fff);
  cursor: pointer;
}

.tag-text-reset {
  color: var(--dark-500, #222729);
  /* SemiBold/12 */
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}

.tag-textone {
  color: var(--dark-500, #222729);
  /* Bold/12 */
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}

.tag-texttwo {
  color: var(--dark-500, #222729);
  /* SemiBold/12 */
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.tag-divider {
  width: 1px;
  align-self: stretch;
  opacity: 0.15;
  background: var(--dark-500, #222729);
}

.tag-icon {
  width: 10px;
  cursor: pointer;
  height: 10px;
  flex-shrink: 0;
}

.table-tag-admin {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  background-color: #e1fdd8;
}

.table-tag-active {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  background-color: #e1fdd8;
}

.table-tag-pending {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  background-color: #fdebd8;
}

.table-tag-text {
  color: var(--dark-500, #222729);
  /* Bold/12 */
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}

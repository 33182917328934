.frame-26 {
  background-color: #343a3d0d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 20px 30px;
  flex-direction: column;
}

.text-wrapper-18 {
  color: var(--dark-500, #222729);
  text-align: center;
  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.frame-27 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.frame-28 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 298px;
}

.assistenza-1 {
  height: 20.56px !important;
  margin-bottom: -0.56px !important;
  position: relative !important;
  width: 20px !important;
}

.text-wrapper-19 {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 117.647% */
}

.oppure {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-transform: uppercase;
}

.button-whatsApp {
  width: 100%;
  margin-bottom: 25px;
}

.frame-29 {
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.icon-instance-node-3 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.text-wrapper-20 {
  color: var(--dark-500);
  font-family: var(--bold-24-font-family);
  font-size: var(--bold-24-font-size);
  font-style: var(--bold-24-font-style);
  font-weight: var(--bold-24-font-weight);
  letter-spacing: var(--bold-24-letter-spacing);
  line-height: var(--bold-24-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

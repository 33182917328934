.service-card-container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
}

.service-card-icon-layout {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 1px 0px rgba(52, 58, 61, 0.1);
}

.service-card-title {
  overflow: hidden;
  color: var(--dark-500, #222729);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
}

.service-card-subtitle {
  overflow: hidden;
  color: var(--dark-500, #222729);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  opacity: 0.5;
}

.credit-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.credit-topbar {
  margin-top: 20px;
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.credit-topbar-title-iconplace {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.credit-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.credit-topbar-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.credit-switchbar-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: fit-content;
  height: 30px;
  font-size: 14px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
}

.credit-header {
  width: 100%;
  display: flex;
}

.button-send-credits {
  display: flex;
  width: 100%;
  justify-content: end;
}

.container-cards {
  display: flex;
  width: 100%;

  gap: 30px;
}

.first-card-credits {
  width: 100%;
  height: 250px;
}

.second-card-credits {
  width: 100%;
  height: 250px;
}

.first-card-credits .ant-card-bordered {
  height: 100%;
}

.second-card-credits .ant-card-bordered {
  height: 100%;
}

.total-credit-topbar {
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.total-credit-topbar-title-iconplace {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.total-credit-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.total-credit-topbar-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.button-recharge-credits {
  display: flex;
  width: 100%;
  justify-content: end;
}

.placeholder-credit-recharge {
  color: black;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.first-row {
  display: flex;
}

.credits-available-text {
  color: var(--dark-300, #7a7d7f);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  margin-top: 15px;

  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.credits-view {
  color: var(--bold-200, #14adcc);
  font-family: Red Hat Display;
  font-size: 50px;
  font-style: normal;
  margin-top: 15px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.last-movement-text {
  color: var(--dark-300, #7a7d7f);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}
.last-movement-icon {
  margin-top: 5px;
  display: flex;
}

.last-movement-price {
  color: black;
  margin-top: 5px;
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.row-last-movement {
  display: flex;
}

.bottom-info {
  display: flex;
  gap: 220px;
}

.date-text {
  color: var(--dark-300, #7a7d7f);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 600;
  line-height: 20px;
}
.date-icon {
  margin-top: 5px;
  display: flex;
}

.date-price {
  color: black;
  margin-top: 5px;
  font-family: Red Hat Display;
  font-size: 16px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.row-date {
  display: flex;
}

.button-activity-view {
  display: flex;
  width: 100%;
  justify-content: end;
}

.movement-topbar {
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.movement-topbar-title-iconplace {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: var(--bold-100, #fff5e0);
  /* background: "#FFF5E0"; */
}

.movement-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.movement-topbar-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.sending-history-text {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.table-sending
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}

.table-sending .ant-table-wrapper .ant-table-thead {
  overflow: hidden;
  color: var(--Dark-100, #030330);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.table-sending .ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f0f0f0;
}
.table-sending-a .ant-table-wrapper .ant-table-thead {
  overflow: hidden;
  color: var(--Dark-100, #030330);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.table-sending-a .ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f0f0f0;
}

.credit-table-last-recharge-container {
  display: flex;
  flex-direction: column;
}

.input-text-style-table-first {
  font-family: Red Hat Display;
  font-weight: 600;
}

.input-text-style-table-last {
  font-family: Red Hat Display;
  color: #7a7d7f;
  font-weight: 300;
}
.view-transactions {
  display: flex;
  margin-top: 20px;
  width: 100%;
  position: relative;
}

.transaction-type {
  width: 30%;
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
}

.transaction-credits {
  width: 30%;
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
}

.transaction-date {
  width: 30%;
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
}

.transaction-time {
  width: 20%;
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: end;
}

.transaction-icon {
  width: 5%;
}

.rectangle-12 {
  background-color: var(--dark-50);
  height: 1px;
  position: absolute;
  width: 100%;
  margin-top: 8px;
}

.transaction-list {
  position: relative;
  bottom: 3px;
}

.table-activity-type {
  display: flex;
}

.table-activity-type-text {
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: medium;
  font-weight: 600;
  margin-left: 10px;
}

.table-activity-credit {
  display: flex;
}

.table-activity-credit-text {
  margin-left: 3px;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: medium;
  font-weight: 600;
}

.table-activity-date {
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: medium;
  font-weight: 600;
}

.table-activity-numPeople {
  display: flex;
}

.table-activity-numPeople-text {
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: medium;
  font-weight: 600;
  margin-left: 5px;
}

.table-activity-button-details .ant-space-gap-col-middle {
  float: right;
}

.table-sending
  .ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 8px;
  justify-content: end;
  display: flex;
  padding-right: 64px;
}

.modal-credits .mn-modal .mn-modal__dialog {
  width: 400px;
  height: 388px;
}

.button-dropdown-select-credits {
  width: max-content;
}

.modal-dropdown-credits {
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px;
}

.modal-dropdown-credits-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

.modal-dropdown-credits-subtitle {
  margin-bottom: 20px;
}

.modal-dropdown-credits-amount {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-dropdown-credits-min {
  color: #909394;
  font-size: 14px;
}

.modal-dropdown-credits-dropdown {
  margin-bottom: 30px;
}

.modal-dropdown-price {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

.modal-dropdown-price-euro {
  color: var(--corp-500);
}

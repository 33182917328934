.summary-containter {
  display: flex;
  padding: 20px 28px 28px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  background: var(--White, #fff);

  /* Shadow section */
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
}

.summary-title {
  color: var(--dark-500, #222729);

  /* Bold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-heavy);
  line-height: 28px; /* 140% */
}

.summary-divider {
  height: 1px;
  align-self: stretch;
  opacity: 0.15;
  background: var(--dark-500, #222729);
}

.summary-first-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.summary-first-row {
  display: flex;
  width: 284px;
  justify-content: space-between;
  align-items: flex-start;
}

.summary-first-section-left {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.summary-first-section-right {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.order-button-wrapper {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  display: flex;
  height: 48px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.order-button-wrapper .button {
  border-radius: 6px;
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  width: 110%;
  height: 48px;
}

.order-button-cancel {
  width: 100%;
  text-align: center;
  color: var(--dark-300, #7a7d7f);
  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

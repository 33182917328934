.mn-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 993;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}

.addmember-modal-wrapper {
  display: flex;
  width: 540px;
  height: auto;
  height: fit-content;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  border-radius: 16px;
  background: #fff;
  z-index: 999;
  left: 35%;
  top: 15%;
}

.addmember-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  width: 100%;
  height: 100%;
}

.addmember-modal-header {
  display: flex;
  padding: 20px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: var(
    --gradients-corp,
    linear-gradient(225deg, #36b39e 0%, #108aa3 100%)
  );
}

.addmember-modal-header-icon {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.5;
}

.addmember-modal-header-text {
  color: var(--White, #fff);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.addmember-modal-footer {
  width: 100%;
  display: flex;
  padding-bottom: 28px;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
  justify-content: space-between;
}

.addmember-modal-content-text-first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: var(--dark-500, #222729);
  margin-left: 36px;
  margin-top: 24px;
  margin-right: 36px;

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.addmember-filtermodal-text-white {
  color: white;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.addmember-icon-remove {
  cursor: pointer;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--dark-150, #bdbebf);
  background: var(--White, #fff);
}

.addmember-email-error {
  color: red;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.addmember-formstyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  padding-right: 36px;
}

.addmember-modal-firstbuttonstyle {
  color: var(--corp-500, #36b39e);
  display: flex;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: fit-content;
  border-color: transparent;
  padding-left: 0px;
  flex-direction: row;
}

.addmember-modal-secondbuttonstyle {
  border-color: #36b39e;
  background-color: #36b39e;
  box-shadow: none;
  height: 40px;
  margin-bottom: 0px;
}

.addmember-modal-resetbuttonstyle {
  border-color: transparent;
  box-shadow: none;
  height: 40px;
  margin-bottom: 0px;
}

.myorders-container {
  width: 100%;
  height: 180px;
  display: flex;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background: var(--White, #fff);
  justify-content: space-between;

  /* Shadow section */
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
}

.myorders-container-secondsection {
  display: flex;
  padding-right: 28px;
  padding-top: 28px;
  padding-bottom: 28px;
  gap: 65px;
  flex-direction: column;
}

.myorders-container-firstsection {
  padding-left: 24px;
  padding-bottom: 24px;
  gap: 28px;
  display: flex;
}
.myorders-container-image-container {
  width: 140px;
  height: 155px;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: relative;
}

.myorders-container-image {
  width: 200%;
  height: 155px;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  transform: translateX(-19%);
}

.myorders-container-information {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-top: 24px;
  padding-left: 28px;
}

.myorders-container-information-first {
  display: flex;
  align-items: center;
  gap: 8px;
}

.myorders-container-information-second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.myorders-container-information-second-row {
  display: flex;
  white-space: nowrap;
}

.myorders-container-information-first-text {
  color: var(--dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.myorders-container-information-second-text-first {
  color: var(--dark-500, #222729);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.myorders-container-information-second-text-second {
  color: var(--dark-500, #222729);
  padding-left: 4px;

  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.myorders-container-buttonsection {
  display: inline-flex;
  align-items: flex-start;
  gap: 20px;
}

.myorders-container-buttonsection-first {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--dark-25, #f4f4f4);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  cursor: pointer;
}

.myorders-container-buttonsection-second {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--dark-100, #d3d4d4);
  background: var(--White, #fff);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.2) inset;
  cursor: pointer;
}

.myorders-container-buttonsection-text {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.myorders-container-buttonandtext {
  color: var(--dark-500, #222729);
  text-align: right;

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  opacity: 0.5;
  cursor: pointer;
}

.name-plate-pointer {
  content: "\2022";
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  height: 3px;
  width: 3px;
  background-color: #000000;
  border-radius: 50px;
}

.paystatus-pending {
  color: var(--yellow-500, #fc6);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.paystatus-canceled {
  color: var(--red-500, #f26549);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.paystatus-completed {
  color: var(--corp-500, #36b39e);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.myorders-pending-container {
  background-color: #cdf6ff6b;
  border: 2px solid #c6f3fd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 70px;
  position: sticky;
}

.myorders-container-pending-secondsection {
  width: 100%;
  height: 180px;
  display: flex;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background: var(--White, #fff);
  justify-content: space-between;

  /* Shadow section */
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
  top: -20px;
  position: relative;
}

.myorders-pending-wrapper {
  padding: 25px;
  padding-top: 5px;
  padding-bottom: 25px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.myorders-pending-section-style {
  align-items: center;
  display: flex;
  gap: 5px;
}

.smartplan-filterbar {
  white-space: nowrap;
  display: flex;
  width: 100%;
}

.smartplan-filterBar-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: fit-content;
  height: 30px;
  font-size: 14px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
}

.smartplan-filterBar-search {
  margin-left: auto;
  display: flex;
  white-space: nowrap;
  gap: 12px;
  align-items: center;
}

.smartplan-filterBar-filterbutton {
  display: flex;
  height: 32px;
  width: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--dark-25, #f4f4f4);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  cursor: pointer;
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.smartplan-filterBar-filterbutton-counter {
  display: flex;
  height: auto;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--corp-500, #36b39e);
}

.smartplan-filterbar-filterbutton-counter-text {
  color: var(--white, #fff);
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.smartplan-filterBar-tags {
  display: flex;
  width: 100%;
}

.smartplan-filterBar-tags-section {
  display: flex;
  gap: 4px;
  white-space: nowrap;
  width: 100%;
  overflow: auto;
}

.smartplan-filterBar-tags-reset {
  white-space: nowrap;
}

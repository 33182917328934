.modal-wrapper-change-password .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
  height: 450px;
}

.button-footer {
  display: flex;
  justify-content: flex-end;
}

.button-footer .mn-modal__dialog-footer .button-ok {
  display: flex;
  height: 40px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 100px;
  height: 40px;
}

.modal-textfield-change-password-empty .text-field input[value=""],
.text-field textarea:placeholder-shown {
  width: 95%;
  overflow: hidden;
  color: var(--dark-250, #909394);

  text-overflow: ellipsis;
  whitespace: nowrap;
  flex: 1 0 0;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-bottom: 15px;
}
.modal-textfield-change-password-not-empty .text-field input,
.text-field textarea:placeholder-shown {
  width: 95%;
  overflow: hidden;
  color: var(--dark-500, #222729);
  text-overflow: ellipsis;
  flex: 1 0 0;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-bottom: 15px;
}

.min-car {
  position: absolute;
  top: 185px;
  left: 510px;
  color: var(--dark-250, #909394);
  text-align: right;

  /* SemiBold/12 */
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}

.icon-textfield {
  position: relative;
  height: 0px;
}

.label-textfield {
  display: flex;
  position: relative;
  margin-bottom: 4px;
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.modal-changepassword-newpassword-titlecontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.modal-changepassword-newpassword-secondtitle {
  color: var(--dark-250, #909394);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  justify-content: right;
  width: 50%;
  display: flex;
}

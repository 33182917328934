.frame {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  padding: 20px 28px 28px;
  position: relative;
}

.frame .text-wrapper {
  align-self: stretch;
  color: var(--dark-500);
  font-family: var(--bold-20-font-family);
  font-size: var(--bold-20-font-size);
  font-style: var(--bold-20-font-style);
  font-weight: var(--bold-20-font-weight);
  letter-spacing: var(--bold-20-letter-spacing);
  line-height: var(--bold-20-line-height);
  position: relative;
}

.frame .rectangle {
  align-self: stretch;
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 100%;
}

.frame .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.frame .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.frame .ID {
  color: var(--dark-500);
  font-family: "Red Hat Display-Bold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .span {
  font-family: var(--bold-14-font-family);
  font-size: var(--bold-14-font-size);
  font-style: var(--bold-14-font-style);
  font-weight: var(--bold-14-font-weight);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
}

.frame .text-wrapper-2 {
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
}

.frame .p {
  color: var(--dark-500);
  font-family: "Red Hat Display-Bold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .stato-pagato {
  color: transparent;
  font-family: "Red Hat Display-Bold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-3 {
  color: #222628;
  font-family: var(--bold-14-font-family);
  font-size: var(--bold-14-font-size);
  font-style: var(--bold-14-font-style);
  font-weight: var(--bold-14-font-weight);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
}

.frame .text-wrapper-4 {
  color: #222628;
  font-family: var(--regular-14-font-family);
  font-size: var(--regular-14-font-size);
  font-style: var(--regular-14-font-style);
  font-weight: var(--regular-14-font-weight);
  letter-spacing: var(--regular-14-letter-spacing);
  line-height: var(--regular-14-line-height);
}

.frame .text-wrapper-5 {
  color: #35b39e;
  font-family: var(--bold-14-font-family);
  font-size: var(--bold-14-font-size);
  font-style: var(--bold-14-font-style);
  font-weight: var(--bold-14-font-weight);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
}

.frame .img {
  align-self: stretch;
  object-fit: cover;
  position: relative;
  width: 140px;
}
.summary-column {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.people-container-add {
  width: 100%;
  height: 150px;
  margin-left: 32px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
  padding: 20px 28px 12px;
}

.orderdetail-container {
  width: 100%;
  height: 180px;
  margin-left: 32px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
  padding: 20px 28px 12px;
}

.order-detail-right-side {
  display: flex;
  flex-direction: column;
  padding: 20px 28px 12px;
  gap: 20px;
}

.order-detail-left-side {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.order-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.calendly-inline-widget {
  height: 850px !important;
  overflow-y: hidden;
  width: 100%;
}

.page-content {
  display: flex;
  padding: 50px 330px 189px 330px;
  background-color: rgb(255, 252, 252);
  flex-direction: column;
  align-items: center;
}

.header-menu {
  background-color: rgb(255, 252, 252);
}

.title-card-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  width: 100%;
}

.card-title {
  height: 465px;
  border-radius: 12px;
  margin-top: 16px;
}

.card-font {
  width: 100%;
  height: 100%;
}

.title-text {
  color: var(--Dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  margin-bottom: 6px;
}

.title-text-2 {
  color: var(--Dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  margin-top: 15px;
}

.title-text-p1 {
  color: var(--Dark-300, #7a7d7f);

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
}

.title-text-p2 {
  color: var(--Corp-500, #36b39e);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.title-text-up {
  margin-top: 20px;
  color: var(--Dark-500, #222729);

  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.price-duration-text-up {
  margin-top: 20px;
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}

.input-title {
  margin-top: 10px;
}

.line-create-service {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 97%;
  left: 30px;
}

.style-header .header-menu .top-bar {
  display: flex;
  padding: 20px 32px;
  width: 100px;
}

.create-service-filterbar {
  width: fit-content;
  display: flex;
  float: right;
  position: relative;
  top: 35px;
  right: 20px;
}

.create-service-filterbar .smartplan-filtermodal-text-white {
  color: black;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.header-menu .top-bar-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  width: 900px;
  line-height: 36px;
}

.title-card-title {
  color: var(--dark-500, #222729);

  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.input-desc {
  margin-top: 10px;
}

.card-typology {
  margin-top: 20px;
  width: 100%;
}

.title-card-typology {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 40px;
  width: 100%;
}

.line-price {
  display: flex;
  justify-content: space-between;
}

.dropdown-typology,
.time-picker {
  flex: 1;
  margin-right: 10px;
}

.session-text-up {
  display: contents;
  margin-top: 20px;
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.duration-text-up {
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.picker .ant-picker-large {
  height: 51px;
}

.input-price {
  flex: 1;
  margin-right: 10px;
  white-space: nowrap;
}

.price-text-up {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.myService-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: -moz-fit-content;
  width: 100%;
  height: 36px;
  font-size: 14px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
  margin-top: 8px;
}
.input-persons {
  flex: 1;
  margin-right: 10px;
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.line-sessions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.base-tab-element {
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  width: 100%;
  height: 28px;
  justify-content: center;
  padding: 4px 12px;
  position: relative;
  align-items: center;
}

.base-tab-element-selected {
  background-color: var(--global-colors-ui-white);
  box-shadow: 0px 1px 0px #2226281a;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
}

.input-person {
  margin-top: 10px;
}

.person-text-up {
  color: var(--Dark-500, #222729);
  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
}

.address-text-up {
  color: var(--Dark-500, #222729);
  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 28px;
  margin-bottom: 8px;
}

.address-sessions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.input-city {
  flex: 1;
  margin-right: 10px;
}

.city-text-up {
  display: contents;
  /* margin-top: 20px; */
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.input-citys {
  margin-top: 8px;
}

.input-province {
  flex: 1;
  margin-right: 10px;
  /* margin-top: 5px; */
}

.province-text-up {
  display: contents;
  /* margin-top: 20px; */
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.input-provinces {
  margin-top: 8px;
}

.input-address {
  flex: 1;
  margin-right: 10px;
  /* margin-top: 5px; */
}

.address-texts-up {
  display: contents;
  /* margin-top: 20px; */
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.inputs-address {
  margin-top: 8px;
}

.input-cap {
  margin-right: 10px;
}

.cap-text-up {
  display: contents;
  color: var(--Dark-500, #222729);

  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.input-caps {
  margin-top: 8px;
}

.title-card-hourly-availability {
  color: var(--dark-500, #222729);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 40px;
  width: 100%;
}

.card-hourly-availability {
  margin-top: 20px;
  width: 100%;
}

.button-upload :where(.css-dev-only-do-not-override-17a39f8).ant-btn-default {
  background-color: #c3e8e2;
}
.text-update {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.service-photo
  .ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error {
  border-color: var(--corp, #b6b9ba);
}

.service-photo
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-name {
  overflow: hidden;
  color: var(--Dark-500, #222729);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.service-photo
  .ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item {
  position: relative;
  height: 60px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.service-photo {
  margin-top: 10px;
}

.text-photo-desc {
  color: #000;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-photo-m {
  color: #000;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  display: flex;
  margin-top: 10px;
}
.text-photo-p {
  color: #000;
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  display: flex;
}

.title-card-photo {
  color: var(--dark-500, #222729);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 40px;
  width: 100%;
}

.card-photo {
  margin-top: 20px;
  width: 100%;
}

.gallery-text {
  color: var(--Dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 15px;
}

.text-t {
  display: flex;
  justify-content: center;
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-o {
  display: flex;
  color: var(--Dark-250, #909394);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-c {
  color: var(--Corp-500, #36b39e);

  /* Bold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.font-gallery .ant-upload-wrapper .ant-upload-drag {
  background: var(--Corp-50, #ebf7f5);
}

.font-gallery
  .ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error {
  border-color: var(--corp, #b6b9ba);
}

.font-gallery
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-name {
  overflow: hidden;
  color: var(--Dark-500, #222729);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.input-desc .ant-input {
  font-size: 16px;
  resize: none;
  height: 250px;
}

.preview-button .ant-btn-default {
  margin-right: 20px;
  border-color: #d3d4d4;
  box-shadow: none;
  height: 35px;
  align-items: center;
}

.draft-dropdown {
  margin-right: 20px;
}

.button-dropdown-draft .ant-btn-default {
  width: 100%;
  height: 35px;
}

.space-preview {
  display: flex;
  align-items: center;
}

.space-draft {
  width: 100%;
  place-content: space-between;
}

.save-button .ant-btn-default {
  border-color: #36b39e;
  background-color: #36b39e;
  box-shadow: none;
  align-items: center;
}

.space-save {
  display: "flex";
  align-items: "center";
}

.button-dropdown-typology .ant-btn-default {
  width: 100%;
  height: 40px;
  margin-top: 10px;
}

.space-dropdown-typology .ant-space-align-center {
  width: 100%;
  place-content: space-between;
}

.button-dropdown-seats .ant-btn-default {
  width: 100%;
  height: 40px;
  margin-top: 6px;
}

.space-dropdown-seats .ant-space-align-center {
  width: 100%;
  place-content: space-between;
}

.button-dropdown-online .ant-btn-default {
  width: 100%;
  height: 40px;
  margin-top: 8px;
}

.space-dropdown-online .ant-space-align-center {
  width: 100%;
  place-content: space-between;
}

.button-dropdown-location .ant-btn-default {
  width: 100%;
  height: 40px;
  margin-top: 8px;
}

.space-dropdown-location .ant-space-align-center {
  width: 100%;
  place-content: space-between;
}

.input-title-edit {
  width: 100%;
  height: 50px;
}
.background-footer {
  background-color: rgb(255, 252, 252);
}

.draft-public {
  position: relative;
  justify-content: end;
  font-size: 12px;
  margin-right: 100px;
  color: red;
  bottom: 21px;
  display: flex;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.input-text-style {
  font-family: Red Hat Display;
  font-weight: 600;
}

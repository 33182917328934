.title .top-bar-title {
  color: var(--dark-500, #222729);

  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.accountdetail-container {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 36px;
}

.accountdetail-container .overlap {
  display: flex;
  height: 100%;
}

.accountdetail-bady-page {
  position: relative;
  margin-right: 40px;
  height: 100%;
  width: 100%;
}

.frame-date {
  display: flex;
  width: 748px;
  height: 50px;
  padding: 8px 28px;
  align-items: center;
  gap: 4px;
  position: relative;
  display: flex;
  padding: 0;
  bottom: 10px;
  margin-bottom: 0;
}

.rectangle-5-date {
  background-color: var(--dark-50);
  height: 2px;
  position: relative;
  margin: 0px 0px 24px 0px;
  display: flex;
  width: 100%;
}

.rectangle-5-change-pasword {
  background-color: var(--dark-50);
  height: 2px;
  position: relative;
  display: flex;
  width: 100%;
  margin: 0px 0px 25px 0px;
}

.frame-date .text-wrapper {
  color: var(--dark-500, #222729);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

/* CSS */
.frame-25-date {
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.frame-date {
  text-align: center;
  font-weight: bold;
  padding: 16px 16px 0px 16px;
  width: 100%;
}

.frame-28-date p {
  margin: 8px 0;
}

.frame-28-date span.span {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  align-self: stretch;
}

.text-wrapper-17 {
  color: var(--dark-500, #222729);
  align-self: stretch;
  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.frame-28-date {
  padding: 0px 16px 16px 16px;
  width: 100%;
}

.frame-summary {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  position: relative;
}

.frame-summary .div-20 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 44px 0px 28px;
  position: relative;
  width: 100%;
}

.frame-summary .div-30 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.frame-summary .div-40 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 224px;
}

.frame-summary .user-no-photo {
  align-items: center;
  background-color: var(--dark-50);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 56px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 56px;
}

.frame-summary .icon-instance-node-2 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.frame-summary .div-50 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame-summary .text-wrapper-200 {
  color: var(--dark-500, #222729);
  text-align: center;
  width: 200px;
  /* Bold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.frame-summary .text-wrapper-300 {
  color: var(--dark-500, #222729);
  text-align: center;
  width: 200px;
  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.frame-summary .text-wrapper-400 {
  color: var(--dark-500, #222729);
  text-align: center;
  width: 200px;
  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.frame-summary .div-60 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.frame-summary .text-wrapper-500 {
  color: var(--dark-500, #222729);
  text-align: center;
  width: 200px;
  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.frame-summary .text-wrapper-600 {
  color: var(--dark-300, #7a7d7f);
  text-align: center;
  width: 200px;
  /* Regular/12 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.frame-summary .button-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.frame-summary .design-component-instance-node {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  gap: 4px !important;
  padding: 4px 12px !important;
}

.frame-summary .rectangle-2 {
  background-color: var(--dark-25);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 4px;
  box-shadow: inset 0px 1px 2px #2226281a;
  height: 4px;
  left: 159px;
  position: absolute;
  top: 14px;
  width: 40px;
}

.frame-summary .subtract {
  height: 97px;
  left: 162px;
  position: absolute;
  top: -81px;
  width: 36px;
}

.frame-summary .div-7 {
  align-items: center;
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 24px 28px 28px;
  position: relative;
  width: 280px;
}

.frame-summary .p {
  align-self: stretch;
  color: var(--dark-100);
  font-family: var(--bold-20-font-family);
  font-size: var(--bold-20-font-size);
  font-style: var(--bold-20-font-style);
  font-weight: var(--bold-20-font-weight);
  letter-spacing: var(--bold-20-letter-spacing);
  line-height: var(--bold-20-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.frame-summary .div-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.frame-summary .div-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame-summary .assistenza-1 {
  height: 20.56px !important;
  margin-bottom: -0.56px !important;
  position: relative !important;
  width: 20px !important;
}

.frame-summary .text-wrapper-7 {
  color: var(--dark-100);
  font-family: "Red Hat Display", Helvetica;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-summary .oppure {
  align-self: stretch;
  color: var(--dark-100);
  font-family: var(--semibold-UP-12-font-family);
  font-size: var(--semibold-UP-12-font-size);
  font-style: var(--semibold-UP-12-font-style);
  font-weight: var(--semibold-UP-12-font-weight);
  letter-spacing: var(--semibold-UP-12-letter-spacing);
  line-height: var(--semibold-UP-12-line-height);
  opacity: 0.7;
  position: relative;
  text-align: center;
}

.frame-summary .button-2 {
  color: var(--dark-100) !important;
  line-height: normal !important;
  white-space: unset !important;
}

.frame-summary .button-3 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  gap: 4px !important;
  height: 40px !important;
  padding: 4px 12px !important;
}

.frame-summary .div-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.frame-summary .text-wrapper-8 {
  color: var(--dark-100);
  font-family: "Red Hat Display", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.tag-summary .tag {
  display: flex;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  height: 10px;
  padding: 8px 8px 8px 5px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  left: 54px;
  position: relative;
  border-radius: 40px;
  background: var(--corp-50, #ebf7f5);
  margin-bottom: 24px;
}

.button-change-password {
  display: flex;
  width: 150px;
  height: 32px;
  float: right;
  bottom: 13px;
  margin-right: 25px;
  position: relative;
}

.button-change-password .button.button-line {
  width: 100%;
  height: 100%;
}

.frame-27-date {
  display: flex;
  width: 100%;
}

.button-change-business-data {
  display: flex;
  width: 150px;
  height: 32px;
  float: right;
  bottom: 80px;
  position: relative;
}

.button-change-personal-data {
  display: flex;
  width: 150px;
  height: 32px;
  float: right;
  margin-right: 25px;
  top: 35%;
  position: relative;
}

.button-change-personal-data .button.button-line {
  width: 100%;
  height: 100%;
}

.frame-25-public-data {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.frame-public-data {
  width: 97%;
  display: flex;
  height: 50px;
  padding: 8px 28px;
  align-items: center;
  gap: 4px;
  position: relative;
  display: flex;
  padding: 0;
  bottom: 10px;
  margin: 10px 0px 0px 15px;
}

.frame-public-data .text-wrapper-public-data {
  color: var(--dark-500, #222729);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  position: relative;
  top: 5px;
}

.text-wrapper-public-data {
  white-space: nowrap;
}

.arrow-public-account-public-data {
  float: right;
  width: 100%;
}

.arrow-public-account-public-data .twenty-ic-arrow-right {
  float: right;
  cursor: pointer;
  position: relative;
  top: 5px;
}

.profile-photo .ant-image .ant-image-img {
  border-radius: 45px;
}

.profile-image-adapter {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-addService .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  height: 260px;
  font-family: var(--global-font-regular);
}

.description-modal-service {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.typology-modal {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.myService-filtermodal-text {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  text-align: left; /* Allinea il testo a sinistra */
}

.myService-filtermodal-text-select {
  color: rgba(128, 128, 128, 0.464);
  font-weight: 370;
  font-size: 16px;
}

.input-title-modal .ant-input {
  width: 100%;
  height: 50px;
}

.button-dropdown-typology-modal .ant-btn-default {
  width: 100%;
  height: 51px;
}

.space-dropdown-typology-modal .ant-space-align-center {
  width: 100%;
  place-content: space-between;
}

/* User Name */
.user .user-name {
  color: var(--dark-500);
  position: relative;
  width: fit-content;
}

.user {
  padding: 4px;
  border-radius: 4px;
  background-position: 50% 50%;
  background-size: cover;
}

.user.no-image {
  align-items: center;
  background-color: var(--dark-50);
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  position: relative;
}

/* User Roundness */
.user.round {
  border-radius: 40px;
}

/* User Size */
.user.size-xs {
  height: 16px;
  width: 16px;
}

.user.size-s {
  height: 24px;
  width: 24px;
}

.user.size-m {
  height: 32px;
  width: 32px;
}

.user.size-l {
  height: 36px;
  width: 36px;
}

/* User Name Font Sizes */
.user.size-xs .user-name {
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-bold);
}

.user.size-s .user-name,
.user.size-m .user-name {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semi-bold);
}

.user.size-l .user-name {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi-bold);
}

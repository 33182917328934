.sidebar-admin {
  background: linear-gradient(
    180deg,
    rgb(53.7, 179, 158.12) 0%,
    rgb(16, 138, 163) 100%
  );
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-super-admin {
  background: linear-gradient(180deg, #022620, #005a6c);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.admin-frame {
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 20px;
  position: absolute;
  top: 80px;
  height: 72vh;
  width: 240px;
  overflow-y: aut;
}

.admin-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  cursor: pointer;
}

.admin-option {
  align-self: stretch !important;
  width: unset !important;
}

.sidebar-icon-node {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.admin-group {
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 6px 8px;
  position: relative;
}

.admin-group-icon {
  height: 24px;
  min-width: 24px;
  opacity: 0.7;
  position: relative;
}

.admin-design-component-node {
  left: 2px !important;
  top: 2px !important;
}

.home-text {
  color: var(--global-colors-ui-white);
  flex: 1;

  font-size: var(--font-size-md);
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  position: relative;
  white-space: nowrap;
}

.admin-divider {
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 20px 8px 4px;
  position: relative;
}

.admin-text {
  color: var(--global-colors-ui-white);

  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);

  line-height: normal;
  margin-top: -1px;
  opacity: 0.5;
  position: relative;
  width: fit-content;
}

.admin-laptop {
  border-color: var(--global-colors-ui-white) !important;
}

.admin-header {
  height: 80px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.admin-img {
  left: 24px;
  position: absolute;
  top: 27px;
}

.admin-group-wrapper {
  border-radius: 6px;
  height: 32px;
  left: 228px;
  position: absolute;
  top: 24px;
  width: 32px;
}

.admin-group-icon {
  height: 20px;
  left: 6px;
  opacity: 0.7;
  position: relative;
  top: 6px;
  width: 20px;
}

.admin-overlap-group {
  height: 20px;
  position: relative;
}

.admin-vector {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.admin-vector-2 {
  height: 7px;
  left: 2px;
  position: absolute;
  top: 6px;
  width: 5px;
}

.admin-vector-3 {
  height: 2px;
  left: 8px;
  position: absolute;
  top: 9px;
  width: 9px;
}

.admin-vector-4 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 15px;
  width: 14px;
}

.admin-hamburger-nav {
  height: 20px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 20px !important;
}

.admin-frame-2 {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
}

.admin-frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 12px;
  position: relative;
}

.admin-MR-wrapper {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  display: flex;
  gap: 4px;
  height: 36px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 36px;
}

.admin-MR {
  color: var(--global-colors-ui-white);

  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);

  line-height: normal;
  position: relative;
  width: fit-content;
}

.admin-frame-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  gap: 4px;
}

.admin-frame-5 {
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.admin-mario-rossi {
  color: var(--global-colors-ui-white);

  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.admin-admin-wrapper {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  display: flex;
  padding: 4px;
  position: relative;
  width: fit-content;
}

.admin-admin {
  color: var(--global-colors-ui-white);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-heavy);
}

.admin-mario-rossi-mail-com {
  align-self: stretch;
  color: var(--global-colors-ui-white);

  font-size: 12px;
  font-weight: var(--font-weight-light);

  opacity: 0.7;
  position: relative;
  white-space: nowrap;
}

.admin-rectangle {
  background: rgba(255, 255, 255, 0.15);
  height: 1px;
  left: 20px;
  position: absolute;
  top: -1px;
  width: 240px;
}

.home-mindoor-form {
  display: flex;
  padding: 24px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  background: var(--White, #fff);
  align-items: flex-end;
  /* Shadow section */
  box-shadow: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
}

.home-mindoor-form-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.home-mindoor-form-content-results {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.home-mindoor-form-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-mindoor-form-header-text-one {
  color: var(--Dark-500, #222729);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-family: "Red Hat Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
}
.home-mindoor-form-header-text-two {
  color: var(--Dark-500, #222729);
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.home-mindoor-form-button-text {
  color: var(--Dark-500, #222729);
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.mindoor-button-infomodal {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text-wrapper-6 {
  color: var(--dark-100, #343a3d);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 1000;
  line-height: 24px; /* 133.333% */
}

.mydiary-title {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.mydiary-text-title {
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.mydiary-text-titletwo {
  color: var(--Dark-500, #222729);
  font-family: "Red Hat Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.mydiary-text {
  color: var(--Dark-500, #222729);

  /* Regular/14 */
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.mydiary-paragraph-container {
  white-space: nowrap;
}

.mydiary-dontshow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: var(--Dark-500, #222729);
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.mindoor-ordersuccess-container {
  display: flex;
  padding: 12px 12px 12px 20px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  width: 100%;
  height: fit-content;
  background: var(--corp-50, #ebf7f5);
}

.mindoor-ordersuccess-textcontainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.mindoor-ordersuccess-text {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.mydiary-result-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.graph-content {
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.graph-icons-container {
  display: flex;
  justify-content: space-between;
  margin-left: 9.5%;
  margin-right: 8%;
}

.mydiary-calendar {
  width: 100%;
  height: 100%;
}

.mydiary-graph {
  height: 100%;
  width: 100%;
}

.mydiary-calendar-viewer {
  color: var(--Dark, #161938);

  /* Bold/16 */
  font-family: "Red Hat Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.mydiary-calendar-todaydate {
  display: flex;
  width: 32px;
  height: 22px;
  padding-left: 5px;
  flex-direction: column;
  justify-content: center;
  color: var(--Corp-500, #36b39e);

  /* SemiBold/14 */
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

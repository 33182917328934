.people-container {
  align-items: flex-start;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
}

.people-container-row {
  width: 100%;
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
}

.people-searchbar {
  display: flex;
  width: calc(100% - 24px);
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
  border-radius: 12px;
  margin-top: 20px;
}

.people-row {
  display: flex;
  width: calc(100% - 24px);
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--BRD-Section, rgba(34, 39, 41, 0.12));
}

.myService-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.myService-topbar-title-iconplace {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.myService-topbar {
  margin-top: 20px;
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.myService-topbar-title {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.myService-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.color-tag-bozza .table-tag-active {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  width: fit-content;
  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  border: 1px solid var(--dark-80, #cdcdd6);
  background-color: transparent;
}

.color-tag-pubblico .table-tag-active {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  width: fit-content;
  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  background: var(--Corp-100, #d7f0ec);
}

.color-tag-approvazione .table-tag-active {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  width: fit-content;
  height: 10px;
  padding: 8px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 40px;
  background: var(--orange-80, #fdebd8);
}

.color-tag-approvazione .table-tag-text {
  color: var(--Red-750, #793225);
  font-family: Red Hat Display;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.users-table
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}

.users-table .ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;

  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f0f0f0;
}

.space-icon-right {
  text-align: end;
}

.title-table {
  margin-left: 65px;
  color: black;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  position: relative;
  line-height: 20px;
}

.title-table-super-admin {
  color: black;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  position: relative;
  line-height: 20px;
}

.role-table {
  color: var(--Dark-500, #222729);
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.insertion-date-table {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.presence-text {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.price-text-table {
  height: 36px;
  align-items: center;
  display: flex;
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.users-table .ant-table-wrapper .ant-table-thead {
  overflow: hidden;
  color: var(--Dark-100, #030330);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Red Hat Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.button-approved-service {
  color: #030330;
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.button-disapproved-service {
  color: #030330;
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.button-approved-primary {
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  display: flex;
  border-radius: 6px;
  background: var(--corp-150, #c3e8e2);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  width: 130px;
}

.button-disapproved-primary {
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  display: flex;
  border-radius: 6px;
  background: #f26448;
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  width: 130px;
}

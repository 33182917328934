.empty-state {
  align-items: center;
  border: 1px solid;
  border-color: var(--dark-50);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 60px 40px;
  position: relative;
  width: 95%;
}

.empty-state .frame-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 28px;
  position: relative;
  width: fit-content;
}

.empty-state .frame-10 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: fit-content;
}

.empty-state .forty-ic-user {
  min-width: 40px !important;
  opacity: 0.2 !important;
  width: unset !important;
}

.empty-state .frame-11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.empty-state .non-sono-presenti {
  color: var(--dark-500, #222729);

  /* SemiBold/20 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.empty-state .che-aspetti-aggiungi {
  color: var(--dark-350, #646869);
  text-align: center;

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.empty-state .element-ic-user-add {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.switch {
  border-radius: 40px;
  height: 24px;
  position: relative;
  width: 44px;
}

.switch .rectangle-4 {
  background-color: var(--global-colors-ui-white);
  border-radius: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.switch .ellipse-3 {
  border: 2px solid;
  border-radius: 4px;
  height: 8px;
  left: 28px;
  position: absolute;
  top: 8px;
  width: 8px;
}

.switch .vector {
  height: 7px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 8px;
}

.switch.style-1-hover {
  background-color: var(--dark-150);
}

.switch.style-1-select-pressed {
  background-color: var(--corp-575);
}

.switch.style-1-select {
  background-color: var(--corp-500);
}

.switch.style-1-pressed {
  background-color: var(--dark-200);
}

.switch.style-1-select-hover {
  background-color: var(--corp-450);
}

.switch.style-1-dis {
  background-color: var(--dark-25);
}

.switch.style-1-default {
  background-color: var(--dark-150);
}

.switch .on {
  left: 22px;
}

.switch .off {
  left: 2px;
}

.switch .style-2-hover {
  box-shadow: 0px 2px 6px #22262833, 0px 1px 0px #22262833;
}

.switch .style-2-select-pressed {
  box-shadow: 0px 2px 6px #22262833, 0px 1px 0px #22262833;
}

.switch .style-2-select {
  box-shadow: 0px 2px 6px #22262833, 0px 1px 0px #22262833;
}

.switch .style-2-select-hover {
  box-shadow: 0px 2px 6px #22262833, 0px 1px 0px #22262833;
}

.switch .style-2-pressed {
  box-shadow: 0px 2px 6px #22262833, 0px 1px 0px #22262833;
}

.switch .style-2-default {
  box-shadow: 0px 2px 6px #22262833, 0px 1px 0px #22262833;
}

.switch.style-1-hover .ellipse-3 {
  border-color: var(--global-colors-ui-white);
  opacity: 0.7;
}

.switch.style-1-pressed .ellipse-3 {
  border-color: var(--global-colors-ui-white);
  opacity: 0.3;
}

.switch.style-1-dis .ellipse-3 {
  border-color: var(--dark-50);
}

.switch.style-1-default .ellipse-3 {
  border-color: var(--global-colors-ui-white);
  opacity: 0.3;
}

.required {
  height: 14px;
  width: 14px;
}

.required .ellipse-3 {
  background-color: red;
  border-radius: 3px;
  height: 6px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 6px;
}

.modal-body {
  height: fit-content;
}

.modal-wrapper-edit-business-data .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  max-width: 650px;
  min-width: 550px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
  height: fit-content;
}

.input-field {
  width: 100%;
}

.modal-body-input-field {
  display: flex;
  width: 100%;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 8px 32px 8px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  overflow-x: hidden;
}

.input-title-first-title {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 6px;
}

.modal-body-input-field {
  display: flex;
  width: 100%;
  margin-top: 15px;
}

.required {
  display: flex;
}

.modal-body-input-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.option {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 6px 8px;
  position: relative;
  width: 240px;
}

.option .icon {
  height: 24px;
  min-width: 24px;
  position: relative;
}

.option .home {
  color: var(--global-colors-ui-white);
  flex: 1;
}

.option .frame {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
}

.option .element {
  color: var(--global-colors-ui-white);
  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);
}

.option .element-ic-dashboard {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.option.hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.option.selected {
  background-color: rgba(255, 255, 255, 0.3);
}

.option.default .icon {
  opacity: 0.7;
}

.option.hover .frame {
  background-color: rgba(255, 255, 255, 0.4);
}

.option.default .frame {
  background-color: rgba(255, 255, 255, 0.4);
}

html,
body,
#root {
  height: 100%;
}

/* .mn-menu:not(.mn-menu--expanded) .mn-menu__nav-list__item div:last-child,
.mn-menu:not(.mn-menu--expanded)
  .mn-menu__nav-list__item--active
  div:last-child {
  display: none;
} */

/* .mn-menu:not(.mn-menu--expanded) .mn-menu__nav-list__item:hover,
.mn-menu:not(.mn-menu--expanded) .mn-menu__nav-list__item--active {
  width: 72%;
} */

.mn-menu__nav-list__item--active {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}

/* .mn-menu.mn-menu.mn-menu--admin
  .mn-menu__nav-list__item.mn-menu__nav-list__item--active {
  background-color: #e3e5e8;
} */

.left-text {
  height: 20px;
  position: relative;
  width: 76px;
}

.left-text .max-car {
  position: absolute;
  text-align: right;
  white-space: nowrap;
}

.left-text .link-off {
  color: var(--dark-250);
}

.left-text .XS {
  top: 0;
}

.left-text .link-on {
  color: var(--corp-500);
}

.left-text .m {
  top: -1px;
}

.left-text .XS.link-on {
  font-size: var(--font-size-sm);
  font-style: var(--bold-12-font-style);
  font-weight: var(--font-weight-semi-bold);
  left: 51px;
  letter-spacing: var(--bold-12-letter-spacing);
  line-height: var(--bold-12-line-height);
}

.left-text .link-on.m {
  font-size: var(--font-size-md);
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  left: 47px;
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
}

.left-text .XS.link-off {
  font-size: var(--semibold-12-font-size);
  font-style: var(--semibold-12-font-style);
  font-weight: var(--semibold-12-font-weight);
  left: 11px;
  letter-spacing: var(--semibold-12-letter-spacing);
  line-height: var(--semibold-12-line-height);
}

.left-text .m.link-off {
  font-size: var(--font-size-md);
  font-style: var(--semibold-14-font-style);
  font-weight: var(--font-weight-medium);
  left: 0;
  letter-spacing: var(--semibold-14-letter-spacing);
  line-height: var(--semibold-14-line-height);
}

.modal-wrapper-privacy-policy .mn-modal .mn-modal__dialog {
  width: 550px;
  height: 640px;
}

.modal-wrapper-privacy-policy
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header {
  position: relative;
  display: flex;
  padding: 15px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px 16px 0px 0px;
  background: white;
}

.modal-wrapper-privacy-policy
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div {
  color: black;
}

.modal-wrapper-privacy-policy .mn-modal {
  background-color: transparent;
}

.separator-privacy-policy {
  background-color: var(--dark-50);
  border-radius: 4px;
  height: 1px;
  position: relative;
  top: 25px;
}

.modal-wrapper-privacy-policy
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-body {
  height: 450px;
}

.checkbox-privacy-policy {
  margin-right: 35px;
  margin-top: 10px;
}

.linkterminiecondizioni {
  color: #36b39e;
}

.text-privacy-policy {
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 23px;
}

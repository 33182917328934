.myteam-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.myteam-content .element-ic-laptop-instance {
  min-width: 20px !important;
  width: unset !important;
}

.myteam-content .twenty-ic-laptop-instance {
  border-color: var(--corp-500) !important;
}

.myteam-content .button-instance {
  left: 458px !important;
  position: absolute !important;
  top: 24px !important;
}

.myteam-content .text-wrapper-18 {
  color: var(--red-500);

  font-size: var(--font-size-sm);
  font-style: var(--bold-12-font-style);
  font-weight: var(--font-weight-semi-bold);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.myteam-content .icon-instance-node-3 {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.myteam-content .element-ic-laptop-2 {
  left: 2px !important;
  top: 2px !important;
}

.myteam-content .element-ic-laptop-3 {
  border-color: var(--global-colors-ui-white) !important;
}

.myteam-activitytail-container {
  width: 100%;
}

.myteam-activitytail-container-results {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 28px;
}

.myteam-activitytail-container-row {
  white-space: nowrap;
  display: flex;
  gap: 28px;
  width: 100%;
  padding-bottom: 24px;
}

.myteam-topbar-title-iconplace {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.myteam-topbar {
  margin-top: 20px;
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.myteam-topbar-title {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.myteam-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.myteam-table-user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.myteam-table-user-name {
  color: var(--corp-500, #36b39e);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.users-table .ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #222729;
}

.users-table .ant-pagination .ant-pagination-item-active a {
  color: #222729;
}

.myteam-activitytail-results-bar {
  justify-content: space-between;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.myteam-activitytail-results-title {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.myteam-activitytail-results-title-one {
  color: #000;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.myteam-activitytail-results-title-two {
  color: #000;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.activity-public-account-tail__wrapper {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--brd-section);
  border-radius: 12px;
  box-shadow: var(--shadow-section);
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.activity-public-account-tail__container {
  align-self: stretch;
  height: 100px;
  min-width: 300px;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.activity-public-account-tail__container-nutritional {
  height: 76px;
  object-fit: cover;
  position: absolute;
  overflow: hidden;
  width: 76px;
  padding-left: 20px;
}

.nutritional-image-container {
  align-items: flex-end;
  display: flex;
}

.activity-public-account-tail__frame {
  cursor: pointer;
  height: 28px;
  right: 16px;
  position: absolute;
  top: 16px;
  width: 28px;
}

.activity-public-account-tail__frame-wrapper {
  align-self: stretch;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  padding: 20px 24px 24px;
  position: relative;
}

.activity-public-account-tail__sections {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.activity-public-account-tail__first-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.activity-public-account-tail__first-section-wrapper {
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.activity-public-account-tail__first-title {
  color: var(--dark-500);
  font-size: var(--font-size-md);
  font-style: var(--regular-14-font-style);
  font-weight: var(--font-weight-light);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.activity-public-account-tail__alert-title {
  color: var(--red-500);

  font-size: var(--font-size-sm);
  font-style: var(--bold-12-font-style);
  font-weight: var(--font-weight-semi-bold);

  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.activity-public-account-tail__second-section {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
}
.activity-public-account-tail__second-section-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 0;
}

.activity-public-account-tail__second-title {
  align-self: stretch;
  color: var(--dark-500);

  font-size: var(--font-size-lg);
  font-style: var(--bold-16-font-style);
  font-weight: var(--font-weight-semi-bold);

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.activity-public-account-tail__third-title {
  color: var(--dark-500);
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Dark-500, #222729);
  font-family: "Red Hat Display";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.name-plate-pointer {
  content: "\2022";
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  height: 3px;
  width: 3px;
  background-color: #000000;
  border-radius: 50px;
}

.activity-public-account-tail__most-popular {
  position: relative;
  background: var(--yellow-500, #fc6);
  display: flex;
  width: 184px;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  padding: 5px 0 calc(var(--d) + 5px);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  justify-content: center;
  align-items: center;
  overflow: hidden;
  left: 0px;
  bottom: 25px;
}

.activity-public-account-tail__most-popular-text {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 180% */
  text-transform: uppercase;
  padding-right: 45px;
}

.activity-public-account-tail__image {
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
  width: auto;
}

.activity-public-account-tail____twentyicarrowlineright {
  cursor: pointer;
}

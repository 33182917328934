.userstable-container {
  width: 100%;
  height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d3d4d4;
  border-radius: 12px;
  overflow: hidden;
}

.userstable-header {
  background: linear-gradient(0deg, #f8f9f9, #f8f9f9),
    linear-gradient(0deg, #e9e9ea, #e9e9ea);
  width: 100%;
  height: 20px;
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9ea;
}

.userstable-header-text {
  font-family: Red Hat Display;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.userstable-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.userstable-content-cards {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 40px;
}

.userstable-content-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.userstable-content-card-user {
  display: flex;
  flex-direction: column;
}

.userstable-content-card-user-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.userstable-content-card-user-row-text-primary {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.userstable-content-textbalance {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #222729;
  right: 60px;
}

.userstable-content-card-user-creditsavabile {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #222729;
}

.home-empty {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.home-empty .div-3 {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  margin: 20px 32px 25px 32px;
  flex-direction: column;
}

.home-empty .sidebar-admin-instance {
  height: 900px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.home-empty .sidebar-admin-2 {
  height: 740px !important;
}

.home-empty .icon-instance-node-2 {
  height: 20px !important;
  left: 2px !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.home-empty .sidebar-admin-3 {
  background-color: #ffffff33 !important;
}

.home-empty .sidebar-admin-4 {
  opacity: unset !important;
}

.home-empty .sidebar-admin-5 {
  background-color: transparent !important;
}

.home-empty .sidebar-admin-6 {
  top: 820px !important;
}

.home-empty .frame-10 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  top: 20px;
}

.home-empty .element-ic-lesson-wrapper {
  align-items: center;
  background-color: #32b39a33;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 5px;
  width: 36px;
}

.home-empty .element-ic-lesson {
  height: 20px !important;
  width: 20px !important;
}

.home-empty .text-wrapper-6 {
  color: var(--dark-500, #222729);

  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}

.home-empty .overlap {
  background-color: #ebf7f5;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 12px;
  height: 90%;
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.home-empty .frame-11 {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 32%;
  padding-bottom: 20px;
}

.home-empty .rectangle-5 {
  align-self: stretch;
  height: auto; /* Lascia che l'altezza si adatti automaticamente */
  max-width: 100%; /* Assicura che l'immagine non superi la larghezza della card */
  margin: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.home-empty .frame-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 27px;
  overflow-y: auto;
  gap: 10px;
  height: 200px;
  min-height: 150px;
}

.home-empty .frame-wrapper-meta {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 27px;
  overflow-y: auto;
  gap: 10px;
  height: 185px;
}

.home-empty .il-coworking {
  color: #000;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
  line-height: 25px;
}

.home-empty .ti-presentiamo {
  align-self: stretch;
  color: #000000;
  font-family: var(--regular-16-font-family);
  font-style: var(--regular-16-font-style);
  font-weight: var(--regular-16-font-weight);
  letter-spacing: var(--regular-16-letter-spacing);
  font-size: 16px;
  line-height: 25px;
}

.home-empty .button-instance {
  display: flex;
  width: 65%;
}

.home-empty .button-instance .button.button-primary {
  background-color: var(--corp-500);
  color: #fff;
  display: flex;
  width: 100%;
}

.home-empty .button-instance .button-text {
  color: #fff;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 5700;
  line-height: 47px;
}

.home-empty .button-instance-meta {
  display: flex;
  width: 65%;
  margin-bottom: 25px;
}

.home-empty .button-instance-meta .button.button-primary {
  background-color: var(--corp-500);
  color: #fff;
  display: flex;
  width: 100%;
}

.home-empty .button-instance-meta .button-text {
  color: #fff;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 5700;
  line-height: 47px;
}

.home-empty .button-2 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: 48px !important;
  padding: 4px 16px !important;
}

.coworking-downloadpdf-button {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.coworking-downloadpdf-button-text {
  color: var(--corp-500, #36b39e);
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.link {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  position: relative;
}

.link .default-instance {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.link .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 0px 4px;
  position: relative;
}

.link .text-wrapper {
  font-family: var(--bold-14-font-family);
  font-size: var(--bold-14-font-size);
  font-style: var(--bold-14-font-style);
  font-weight: var(--bold-14-font-weight);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.link.on {
  padding: 2px 6px;
}

.link.on.dark {
  background-color: var(--dark-25);
}

.link.on.corp {
  background-color: var(--corp-50);
}

.link.dark .text-wrapper {
  color: var(--dark-40);
}

.link.corp .text-wrapper {
  color: var(--corp-500);
}

.button-download {
  height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.title-card {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  display: flex;
  position: relative;
  width: 100%;
  left: 25px;
  margin-top: 10px;
}

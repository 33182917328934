.modal-overlay {
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 1013px;
  height: 520px;
  overflow: hidden;
  text-align: center;
}

.modal-content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 540px;
  height: 591px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-cancel-button {
  margin-right: 10px;
  background-color: #ccc;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-ok-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.modal {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 450px;
  justify-content: space-between;
  position: relative;
  width: 540px;
}

.base-header-pop {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgb(53.7, 179, 158.12) 0%,
    rgb(16, 138, 163) 100%
  );
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 20px 32px;
  position: relative;
  width: 100%;
}

.modal .title-popup {
  color: var(--white);
  font-family: var(--bold-20-font-family);
  font-size: var(--bold-20-font-size);
  font-style: var(--bold-20-font-style);
  font-weight: var(--bold-20-font-weight);
  letter-spacing: var(--bold-20-letter-spacing);
  line-height: var(--bold-20-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.description-modal {
  align-self: stretch;
  color: var(--dark-500, #222729);

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.rectangle-5 {
  background-color: var(--dark-50);
  height: 1px;
  position: relative;
  margin: 20px 0px 20px 0px;
}

.string-date-modal {
  color: var(--dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-bottom: 10px;
}
.frame {
  align-items: flex-start;
  display: flex;
  gap: 28px;
  position: relative;
}

.buy-frame .person-form {
  margin-left: 115px;
}

.buy-frame {
  align-items: flex-start;
  display: flex;
  gap: 28px;
  position: relative;
}

.buy-frame .form-instance {
  width: 160px !important;
}

.buy-frame .form-2 {
  align-self: stretch !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  width: 100% !important;
}

.buy-frame .div-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.buy-frame .form-3 {
  flex: 1 1 !important;
  flex-grow: 1 !important;
  width: 100px;
}

.frame .form-instance {
  width: 160px !important;
}

.frame .form-instance .label {
  align-items: center;
  display: flex;
  position: relative;
  width: 300px;
  margin-bottom: 4px;
}

.frame .form-2 {
  align-self: stretch !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  width: 100% !important;
}

.frame .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.frame .form-3 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: 0px;
}

.frame .form-3 .label {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: 0px;
  align-items: center;
  display: flex;
  position: relative;
}

.desc-modal {
  color: var(--dark-500, #222729);

  /* Regular/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

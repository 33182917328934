.wellness-body-filtermodal-switchsection {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.wellness-filtermodal-content-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
  flex: 1 0 0;
}

:root {
  /* Colors */
  /* 01 - Fill */
  --global-colors-ui-black: #000000;
  --global-colors-ui-primary: #35b39e;
  --global-colors-ui-primary-dark: #343a3d;
  --global-colors-ui-primary-transparent: #1b5a4f80;
  --global-colors-ui-primary-overlay: #161938b2;
  --global-colors-ui-primary-light: #161938;
  --global-colors-ui-secondary-light: #ffcb661a;
  --global-colors-ui-primary-charcoal: #222628;
  --global-colors-ui-primary-gray: #646464;
  --global-colors-ui-primary-hover: #ffffff4c;
  --global-colors-ui-secondary: #d3d4d4;
  --global-colors-ui-white: #ffffff;
  --global-colors-ui-black-secondary: #222729;
  /* Colors */
  --blue-50: rgba(231, 247, 250, 1);
  --shadow-section: 0px 1px 2px 0px rgba(52, 58, 61, 0.05);
  --white: rgba(255, 255, 255, 1);
  --brd-section: rgba(34, 39, 41, 0.12);
  --corp-100: rgba(215, 240, 236, 1);
  --corp-150: rgba(195, 232, 226, 1);
  --corp-250: rgba(155, 217, 206, 1);
  --corp-475: rgba(64, 183, 163, 1);
  --corp-50: rgba(235, 247, 245, 1);
  --corp-500: rgba(54, 179, 158, 1);
  --corp-575: rgba(51, 170, 150, 1);
  --dark-100: rgba(52, 58, 61, 1);
  --dark-150: rgba(189, 190, 191, 1);
  --dark-25: rgba(244, 244, 244, 1);
  --dark-250: rgba(144, 147, 148, 1);
  --dark-300: rgba(122, 125, 127, 1);
  --dark-50: rgba(233, 233, 234, 1);
  --dark-500: rgba(34, 39, 41, 1);
  --red-500: rgba(242, 101, 73, 1);

  /* Typography */
  --font-weight-lighter: 300;
  --font-weight-light: 400;
  --font-weight-regular: 500;
  --font-weight-medium: 600;
  --font-weight-semi-bold: 700;
  --font-weight-bold: 800;
  --font-weight-heavy: 900;

  /* Font Size Variables */
  --font-size-xxs: 8px;
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 18px;
  --font-size-xxl: 20px;
}

/* General */
* {
  scrollbar-color: var(--global-colors-ui-primary) transparent;
  scrollbar-width: thin;
}

body {
  margin: 0;
  user-select: none;
  font-family: "Red Hat Display";
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

/* Scrollbar */

/* Chrome, Edge, and Safari */

/* width */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: var(--global-colors-ui-primary);
  border-radius: 100px;
  border: 4px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: var(--global-colors-ui-primary);
  border: 2px solid transparent;
  border-radius: 100px;
  background-clip: content-box;
}

:where(.css-dev-only-do-not-override-17a39f8).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--corp-50, #ebf7f5);
}

:where(.css-dev-only-do-not-override-17a39f8).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--corp-500, #36b39e);
}

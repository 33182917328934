.myteam-filterbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.myteam-filterBar-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: fit-content;
  height: 30px;
  font-size: 14px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
}

.myteam-filterBar-search {
  margin-left: auto;
  display: flex;
  white-space: nowrap;
  gap: 36px;
  align-items: center;
}

.myteam-filterbar-leftsection .myService-filterBar-search {
  margin-left: auto;
  display: flex;
  white-space: nowrap;
  gap: 36px;
  align-items: center;
}

.myService-filterbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.myService-filterbar-leftsection {
  display: flex;
  gap: 12px;
}

.myteam-filterbar-button {
  display: flex;
  height: 30px;
  gap: 4px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--corp-500, #36b39e);
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.1) inset;
  cursor: pointer;
}

.myteam-filterbar-button-text {
  color: #fff;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.title-text {
  color: var(--Dark-500, #222729);

  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.text-service {
  color: #000;

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  display: flex;
}

.text-length {
  color: #000;

  /* Regular/16 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

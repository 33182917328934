.public-account-filterbar {
  width: fit-content;
  display: flex;
  float: right;
  position: relative;
  top: 35px;
  right: 20px;
}

.public-account-filterbar .smartplan-filtermodal-text-white {
  color: black;
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.preview-button .ant-btn-default {
  margin-right: 20px;
  border-color: #d3d4d4;
  box-shadow: none;
  height: 35px;
  align-items: center;
}

.space-preview {
  display: flex;
  align-items: center;
}

.save-button .ant-btn-default {
  border-color: #36b39e;
  background-color: #36b39e;
  box-shadow: none;
  align-items: center;
}

.space-save {
  display: "flex";
  align-items: "center";
}

.header-menu {
  background-color: rgb(255, 252, 252);
}

.style-header .header-menu .top-bar {
  display: flex;
  padding: 20px 32px;
  width: 100px;
}

.header-menu .top-bar-title {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  line-height: 36px;
}

.line-create-service {
  background-color: var(--dark-500);
  height: 1px;
  opacity: 0.15;
  position: relative;
  width: 97%;
  left: 30px;
}

.public-data-text-title {
  color: var(--Dark-500, #222729);
  padding: 40px 0px 40px 178px;
  /* Bold/18 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.input-card {
  padding: 0px 178px 100px 178px;
}

.input-card .ant-card-body {
  height: 100%;
}

.page-cont {
  background: var(--Dark-5, #f8f9f9);
}

.who-are-you {
  display: flex;
}

.who-are-you-text {
  color: var(--Dark-500, #222729);

  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.dropdown-whoyouare .ant-btn-default {
  margin-right: 20px;
  border-color: #d3d4d4;
  box-shadow: none;
  height: 35px;
  align-items: center;
  width: 100%;
}

.required {
  position: relative;
  top: 1px;
}

.public-account-switchbar-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: 100%;
  height: 45px;
  font-size: 15px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
  margin-top: 15px;
}

.personal-photo {
  display: flex;
  padding-top: 28px;
}

.personal-photo-text {
  color: var(--Dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.input-dragger {
  border-radius: 8px;
  border: 1px dashed var(--Corp-250, #9bd9ce);
  background: var(--Corp-50, #ebf7f5);
  margin-top: 15px;
}
/* .input-dragger .ant-upload-wrapper .ant-upload-drag {
  background: var(--Corp-50, #ebf7f5);
} */

.input-dragger
  .ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error {
  border-color: var(--corp, #b6b9ba);
}

.input-dragger
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-name {
  overflow: hidden;
  color: var(--Dark-500, #222729);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.recommended-size {
  padding-top: 10px;
}

.recommended-size-text {
  display: flex;
  color: #000;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}

.recommended-size-text-desc {
  color: #000;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.personal-data-and-professional {
  display: flex;
  padding-top: 28px;
  padding-bottom: 12px;
}

.personal-data-and-professional-text {
  color: var(--Dark-500, #222729);

  /* Bold/16 */
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.first-row-info {
  width: 100%;
  justify-content: space-between;
  display: flex;
  gap: 24px;
}

.preferred-shape-dropdown {
  width: 100%;
  margin: 0px;
}

.preferred-shape-dropdown .ant-btn-default {
  height: 40px;
  width: 100%;
  display: grid;
}

.space-preferred-shape {
  display: contents;
}

.space-preferred-shape .ant-space-align-center {
  justify-content: space-between;
  position: relative;
  top: 2px;
}

.dropdown-whoyouare .space-preferred-shape .ant-space-align-center {
  position: relative;
  justify-content: left;
  width: 100%;
  top: 0;
}

.dropdown-whoyouare .ant-space-item {
}

.input-name {
  width: 100%;
}

.input-name .ant-input {
  height: 40px;
}

.input-surname {
  width: 100%;
}

.input-surname .ant-input {
  height: 40px;
}

.required-text {
  display: flex;
}

.text-input-name {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-input-surname {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-dropdown-preferred-shape {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.description-text {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.input-description {
  width: 100%;
}

.input-description .ant-input {
  height: 55px;
}

.third-row-info {
  padding-top: 15px;

  width: 100%;
  justify-content: space-between;
  display: flex;
  gap: 24px;
}

.input-city-public-account {
  margin-top: 5px;
  flex: 1;
}

.input-years {
  margin-top: 5px;
  width: 50%;
}
.input-years .ant-input {
  height: 40px;
}
.text-input-years {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.input-city-public-account .ant-input {
  height: 40px;
}

.text-input-city {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.input-specializations {
  padding-top: 28px;
}

.input-specializations .ant-select {
  width: 100%;
}

.text-input-specializations {
  color: var(--Dark-500, #222729);
  padding-bottom: 6px;
  /* SemiBold/14 */
  padding-bottom: 5px;
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.training-and-skills-text {
  color: var(--Dark-500, #222729);
  font-family: Red Hat Display;
  padding-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.input-training-and-skills .ant-input {
  height: 40px;
}

.fifth-row-info {
  padding-top: 15px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  gap: 24px;
}

.input-email {
  margin-top: 5px;
  width: 50%;
}
.input-email .ant-input {
  height: 40px;
}
.text-input-email {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.text-input-email-desc {
  color: var(--Dark-500, #222729);
  padding-top: 12px;
  padding-bottom: 6px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  padding-left: 2px;
}

.input-telephone {
  margin-top: 5px;
  width: 50%;
}
.input-telephone .ant-input {
  height: 40px;
}
.text-input-telephone {
  color: var(--Dark-500, #222729);
  padding-bottom: 5px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.text-input-telephone-desc {
  color: var(--Dark-500, #222729);
  padding-top: 12px;
  padding-bottom: 6px;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.switch-telephone {
  display: flex;
  gap: 10px;
}

.input-text-style {
  font-family: Red Hat Display;
  font-weight: 600;
}

.custom-select-public-account-specalizations .ant-select-selector {
  min-height: 40px; /* Assicurati che il contenitore abbia una min-height di 40px */
  height: auto; /* Permetti all'altezza di adattarsi al contenuto */
}

.modal-wrapper-transaction-recharge-detail .mn-modal .mn-modal__dialog {
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 450px;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
  height: 371px;
}

.summary-text {
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: medium;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 15px;
}

.summary-credit {
  top: 50px;
  position: relative;
}

.rectangle-10 {
  background-color: var(--dark-50);
  height: 1px;
  position: absolute;
  width: 100%;
}

.container-credits {
  width: 100%;
  display: flex;
}

.credit-recharge {
  display: flex;
  width: 50%;
  margin-bottom: 10px;
}

.credit-recharge-text {
  display: flex;
  position: relative;
  justify-content: end;
  width: 50%;
}

.container-price {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.price-recharge {
  display: flex;
  padding-bottom: 10px;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: medium;
  font-weight: 600;
  width: 50%;
}

.price-recharge-text {
  display: flex;
  position: relative;
  width: 50%;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: medium;
  justify-content: end;
  font-weight: 600;
}

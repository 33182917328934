.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-containter {
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Toast Notification style */
.Toastify
  .Toastify__toast-container
  .Toastify__toast-theme--colored.Toastify__toast--success {
  padding: 0;
  min-height: unset;
  max-height: unset;
  border-radius: 8px;
  background: var(--corp-500, #36b39e);
  box-shadow: 0px 3px 4px 0px rgba(34, 39, 41, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1000;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast-theme--colored.Toastify__toast--success
  .toast-title {
  color: var(--white, #fff);
  /* Semibold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  z-index: 1000;
  line-height: 20px;
  /* 142.857% */
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast-theme--colored.Toastify__toast--error {
  /* min-height: unset; */
  /* max-height: unset; */
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--dark-500, #222729);
  box-shadow: 0px 3px 4px 0px rgba(34, 39, 41, 0.1);
  height: 10%;
  z-index: 1000;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast-theme--colored.Toastify__toast--error
  .toast-title {
  color: var(--red-500, #f26549);
  /* Semibold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  z-index: 1000;
}

.Toastify
  .Toastify__toast-container
  .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--global-colors-ink-dark);
  padding: 0;
  min-height: unset;
  max-height: unset;
}

.Toastify__toast-theme--colored.Toastify__toast {
  color: var(--global-colors-ui-white);
}

.toast-wrapper .Toastify__toast-container {
  position: fixed;
  padding: 4px;
  box-sizing: border-box;
  color: var(--global-colors-ui-white);
  width: 270px;
  left: inherit;
}

.toast-wrapper {
  position: absolute;
  z-index: 999;
}

.toast-wrapper .Toastify__close-button svg {
  color: var(--global-colors-ui-white);
  background: transparent;
  outline: none;
  border: none;
  cursor: var(--global-cursor-pointer);
  width: 17px;
  height: 19px;
}

.toast-wrapper .Toastify__toast-body {
  padding: 15px;
  padding-right: 0;
  z-index: 999;
}

.toast-wrapper .Toastify__toast-body + .Toastify__close-button {
  width: 12px;
  margin: 18px 18px auto 0;
}

.toast-wrapper .Toastify__close-button {
  opacity: 1;
}

.toast-wrapper
  .Toastify__toast-icon
  .Toastify--animate-icon
  .Toastify__zoom-enter {
  width: 16px;
}

.ant-spin {
  margin-top: 50px;
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--global-colors-ui-primary);
}

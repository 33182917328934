.base-input {
  color: #000;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  height: 32px;
  padding: 0px 8px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--dark-150, #bdbebf);
  background: #fff;
}

.base-displayer {
  color: #000;
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.base-input .default-2 {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}

.base-input .frame-4 {
  display: flex;
  flex: 1;
  padding: 0px 4px;
  position: relative;
}

.base-input .ricerca-membri-2 {
  flex: 1;

  font-size: 14px;
  font-weight: var(--font-weight-medium);
  height: 20px;

  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.base-input.text {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--dark-150);
  display: flex;
}

.base-input.focus-text {
  background-color: var(--global-colors-ui-white);
  /* border: 2px solid; */
  border-color: var(--corp-500);
  display: flex;
}

.base-input.focus {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--corp-500);
  display: flex;
}

.base-input.hover {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--dark-150);
  display: flex;
}

.base-input.disabled {
  background-color: var(--dark-5);
  border: 1px solid;
  border-color: var(--dark-100);
  display: flex;
}

.base-input.default {
  background-color: var(--global-colors-ui-white);
  border: 1px solid;
  border-color: var(--dark-150);
  display: flex;
}

.base-input.text .frame-4 {
  align-items: flex-start;
}

.base-input.focus-text .frame-4 {
  align-items: flex-start;
}

.base-input.focus .frame-4 {
  align-items: flex-start;
}

.base-input.hover .frame-4 {
  align-items: flex-start;
}

.base-input.disabled .frame-4 {
  align-items: flex-start;
  opacity: 0.3;
}

.base-input.default .frame-4 {
  align-items: center;
}

.base-input.text .ricerca-membri-2 {
  color: var(--dark-500);
}

.base-input.focus-text .ricerca-membri-2 {
  color: var(--dark-500);
}

.base-input.focus .ricerca-membri-2 {
  color: var(--dark-150);
}

.base-input.hover .ricerca-membri-2 {
  color: var(--dark-250);
}

.base-input.disabled .ricerca-membri-2 {
  color: var(--dark-500);
}

.base-input.default .ricerca-membri-2 {
  color: var(--dark-250);
}

.text-prev {
  margin-left: 3px;
  height: 20px;
  flex: 1 0 0;
  position: absolute;
  color: var(--dark-250, #909394);
  text-overflow: ellipsis;
  /* SemiBold/14 */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.meetings-content {
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  gap: 22px;
  min-height: 90vh;
}

.meetings-topbar-title-iconplace {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: rgba(50, 179, 154, 0.2);
}

.meetings-topbar {
  margin-top: 20px;
  gap: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.meetings-topbar-title {
  color: var(--dark-500, #222729);
  /* Bold/24 */
  font-family: Red Hat Display;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.meetings-topbar-title-iconplace-icon {
  width: 20px;
  height: 20px;
}

.meetings-report-title-card {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  width: 50%;
}

.meetings-report-line-chart {
  width: 20px;
  height: 4px;
  background-color: var(--global-colors-ui-primary);
  margin: 12px;
}

.meetings-report-first-row-card {
  display: flex;
}

.meetings-report-profit-text {
  display: flex;
  width: 50%;
  justify-content: end;
}

.meetings-report-profit-text-font {
  color: var(--dark-500, #222729);
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  width: 174px;
}

.container-chart-report-meetings {
  width: 100%;
  height: 200px;
}

.chart-report {
  height: 100%;
}

.chart-report .apexcharts-menu-icon {
  display: none;
}

.meetings-report-profit-title {
  color: #7a7d7f;
  font-size: 14px;
  font-family: Red Hat Display;
  line-height: 20px;
  border-style: hidden;
  margin-top: 12px;
  font-weight: bolder;
}

.meetings-report-profit {
  color: #222628;
  font-size: 38px;
  font-family: Red Hat Display;
  line-height: 44px;
  border-style: hidden;
  font-weight: 700;
}

.line-vertical {
  margin-top: 10px;
  height: 100px;
  width: 1px;
  background-color: lightgray;
  margin-left: 20px;
  margin-right: 20px;
}

.meetings-report-second-row-card {
  display: flex;
}

.meetings-report-last-profit-title {
  display: flex;
}

.meetings-report-last-profit-text {
  color: #222628;
  vertical-align: text-top;
  font-size: 16px;
  font-family: Red Hat Display;
  font-weight: 1000;
}

.meetings-report-last-profit-result {
  color: #222628;
  vertical-align: text-top;
  font-size: 16px;
  font-family: Red Hat Display;
  font-weight: 700;
  position: relative;
}

.meetings-report-not-profit {
  color: #35b39e;
  font-size: 38px;
  font-family: Red Hat Display;
  line-height: 44px;
  border-style: hidden;
  font-weight: 700;
}

.meetings-report-total-profit {
  color: #222628;
  font-size: 25px;
  font-family: Red Hat Display;
  line-height: 44px;
  border-style: hidden;
  font-weight: 700;
}

.meetings-topbar-commands-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
}

.meetings-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.meetings-table-header-textresult {
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.meetings-table-header-filter-text-one {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.meetings-table-header-filter-text-two {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.meetings-button-confirm-all-invoiced {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 30px;
  border: 1px solid #d3d4d4;
  border-radius: 5px;
  cursor: pointer;
}

.meetings-filtermodal {
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px;
}

.meetings-filtermodal-inputs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.meeting-filtermodal-input-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-bottom: 10px;
}

.meeting-filtermodal-text {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.meetings-switchbar-tabs {
  display: flex;
  padding: 2px;
  gap: 2px;
  border-radius: 6px;
  background: var(--dark-50, #e9e9ea);
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-style: var(--bold-14-font-style);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--bold-14-letter-spacing);
  line-height: var(--bold-14-line-height);
  white-space: nowrap;
  align-items: center;
}

.meetings-statusmodal-text {
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.meetings-statusmodal-preview-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.meetings-statusmodal-preview-text-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.meetings-statusmodal-checkbos-section {
  display: flex;
  flex-direction: column;
}

.modal-status-meeting .mn-modal__dialog {
  height: auto;
  max-height: 700px;
  width: 550px;
}

.meeting-table-tworows-record {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meeting-table-primary-text {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.meeting-table-secondary-text {
  font-family: Red Hat Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.meetings-button-table-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  border: 1px solid #d3d4d4;
  border-radius: 5px;
  cursor: pointer;
}

.modal-invoice-meeting {
  position: absolute;
  width: 400px;
  height: 265px;
  background-color: white;
  padding-left: 24px;
  padding-right: 24px;
  gap: 30px;
  border-radius: 12px 12px 12px 12px;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-invoice-meeting-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 993;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-invoice-meeting-text-one {
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.modal-invoice-meeting-text-two {
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
}

.meetings-invoice-modal-buttons-container {
  display: flex;
  height: auto;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.meetings-invoice-button-delete {
  background-color: #40b7a3;
  height: 40px;
  width: 100%;
}

.meetings-invoice-button-dimiss {
  background-color: #ffffff;
  height: 40px;
  width: 100%;
}

.meetings-invoice-button-dimiss-text {
  color: #222628;
  font-size: 14px;
  font-family: Red Hat Display;
  font-weight: 700;
}

.meetings-invoice-button-cancel-text {
  color: #ffffff;
  font-size: 14px;
  font-family: Red Hat Display;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.meeting-table-footer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: space-between;
}

.meeting-table-footer-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meeting-table-footer-section-text-one {
  font-family: Red Hat Display;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
}

.meeting-table-footer-section-text-two {
  font-family: Red Hat Display;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #36b39e;
  background-color: #36b39e;
}

.meetings-secondbutton-feedback {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  padding: 4px 0;

  border: 1px solid var(--dark-100, #d3d4d4);
  background: var(--White, #fff);
  cursor: pointer;
  box-shadow: 0px -1px 0px 0px rgba(52, 58, 61, 0.2) inset;
  height: 48px;
  background-color: #c3e8e2;
}

.meetings-wrapper {
  background: var(---500, #50c3af);
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ant-table-cell {
  font-family: "Red Hat Display";
  font-size: 14px;
}
